import styled from "styled-components";

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 3rem;
  > button:nth-of-type(1) {
    margin-right: 1rem;
  }
`;
