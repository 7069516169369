import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#666",
    },
    secondary: {
      main: "rgb(255, 210, 66)",
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        "&$disabled:before": {
          borderBottomStyle: "none",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#000000",
        "&$disabled:before": {
          border: "none",
        },
      },
    },
    MuiTableCell: {
      paddingNone: {
        padding: "0!important", //for material-table action column
      },
    },
  },
});
