import moment from "moment";
import { dateFormat, dateFormatIso, datetimeFormatIso, dateMonthFormatIso } from "utils/constants";
import { format } from "date-fns";

export const getDate = (date: any) => (date ? moment(date, dateFormat) : null);

export const getDateIso = (date: any) => (date ? moment(date, dateFormatIso) : null);

export const getDateMonthIso = (date: any) => (date ? format(date, dateMonthFormatIso) : null);

export const getDateFormatted = (date: any) => (date ? getDate(date)!.format(dateFormat) : null);

export const getDateFormattedIso = (date: any) => (date ? getDate(date)!.format(dateFormatIso) : null);

export const getDatetimeFormattedIso = (date: any) => (date ? getDate(date)!.format(datetimeFormatIso) : null);
