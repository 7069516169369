export const apiUrl = "/api";

export enum RoutesList {
  Root = "/",
  EmployeeDetail = "/employee",
  Reports = "/reports",
  Disconuts = "/discounts",
  RatesChanges = "/rates-changes",
  Employees = "/employees",
  Margins = "/margins",
}

export const authLogin = `${apiUrl}/authentication/login`;

export const GET_ALL_RATES = `${apiUrl}/margins`;
export const GET_ALL_RATES_CSV = `${apiUrl}/margins/csv`;
export const GET_ALL_CLIENT_MARGINS = `${apiUrl}/margins/clients`;
export const GET_ALL_POSITION_CATEGORY_MARGINS = `${apiUrl}/margins/position-categories`;
export const CALCULATE_RATES = `${apiUrl}/employee-daily-rate/calculateRates`;
export const SAVE_NEW_RATE = `${apiUrl}/employee-daily-rate`;
export const UPDATE_EMPLOYEE_RATE = (rateId: number) => `${apiUrl}/employee-daily-rate/${rateId}`;
export const DELETE_EMPLOYEE_RATE = (rateId: number) => `${apiUrl}/employee-daily-rate/${rateId}`;

export const EMPLOYEE_DETAILS = (employeeUid: string) => `${apiUrl}/employees/${employeeUid}`;
export const CLIENT_RATE_HISTORY = (employeeUid: string) => `${apiUrl}/client-daily-rate/${employeeUid}`;
export const EMPLOYEE_RATE_HISTORY = (employeeUid: string) => `${apiUrl}/employee-daily-rate/${employeeUid}`;

export const ADD_CLIENT_RATE = `${apiUrl}/client-daily-rate`;
export const UPDATE_CLIENT_RATE = (rateId: number) => `${apiUrl}/client-daily-rate/${rateId}`;
export const DELETE_CLIENT_RATE = (rateId: number) => `${apiUrl}/client-daily-rate/${rateId}`;
export const UPDATE_EMPLOYEE_CLIENT_DATA = (employeeId: number) => `${apiUrl}/employees/${employeeId}/client-data`;

export const ADD_EMPLOYEE_DISCOUNT_RULE = `${apiUrl}/client-rate-discount-rules/employee`;
export const ADD_VOLUME_DISCOUNT_RULE = `${apiUrl}/client-rate-discount-rules/volume`;

export const EXCHANGE_CURRENCY_TO_PLN = `${apiUrl}/client-dailpy-rate/currency-exchange/to-pln`;

export const GET_ALL_CLIENTS = `${apiUrl}/dictionary/clients`;
export const GET_ALL_EMPLOYEES = `${apiUrl}/dictionary/employees`;
export const GET_ALL_POSITIONS = `${apiUrl}/employees/position-categories`;
export const GET_ALL_PROJECTS = `${apiUrl}/dictionary/projects`;
export const GET_ALL_CURRENCIES = `${apiUrl}/dictionary/currencies`;
export const GET_ALL_LOCATIONS = `${apiUrl}/dictionary/locations`;
export const GET_ALL_CLIENT_DISCONUT_RULES = `${apiUrl}/client-rate-discount-rules`;
export const GET_ALL_CLIENT_POSITION_CATEGORIES = `${apiUrl}/dictionary/client-position-categories`;
export const GET_RATES_CHANGES = `${apiUrl}/employee-daily-rate/history`;
export const EXPORT_RATES_CHANGES = `${apiUrl}/employee-daily-rate/history/csv`;

export const getClientRateReport = `${apiUrl}/report/client-rate`;
export const getEmployeeRateReport = `${apiUrl}/report/employee-rate`;

export const GET_MARGIN_REPORT = `${apiUrl}/report/margin/csv`;
export const GET_MARGIN_REPORT_FOR_CLIENT = (client: string) => `${apiUrl}/report/margin/csv?clientName=${client}`;

export const GET_CUSTOM_REPORTS = `${apiUrl}/reports/custom`;
export const GET_CUSTOM_REPORT = (reportName: string) => `${apiUrl}/reports/custom/view/${reportName}`;
export const GET_CUSTOM_REPORT_CSV = (reportName: string) => `${apiUrl}/reports/custom/${reportName}`;

export const GET_ALL_LATEST_ACCOUNTS = `${apiUrl}/employees-with-rates-and-first-date-in-account`;

export const GET_EMPLOYEES = `${apiUrl}/employees`;
export const GET_EMPLOYEES_CSV = `${apiUrl}/employees/csv`;

export const EMPLOYEE_RATE_EDIT_HISTORY = (rateId: number) => `${apiUrl}/employee-daily-rate/${rateId}/history`;

export const CURRENCY_EXCHANGE_RATE = `${apiUrl}/currency/exchange-rate`;
export const EXPORT_EMPLOYEE_RATES = `${apiUrl}/enova/employee-rates`;
export const EXPORT_CLIENT_RATES = `${apiUrl}/enova/client-rates`;
