import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Grid, LinearProgress } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { EMPLOYEE_DETAILS } from "router/url";
import { OutlinedButton } from "components/Button";
import { useFetch } from "utils/hooks/useFetch";
import EmployeeBasicInfo from "./components/EmployeeBasicInfo";
import EmployeeRatesOverview from "./components/EmployeeRatesOverview";
import { IEmployee } from "./EmployeeDetails.types";
import * as S from "./EmployeeDetails.css";

const EmployeeDetails = () => {
  let history = useHistory();
  let { employeePendingLdapLogin } = useParams();
  const [isLoading, toggleLoading] = useState(true);
  const [employee, setEmployeeDetails] = useState<IEmployee>();

  const getEmployeeRateDetails = useFetch();

  const getEmployeeDetails = useCallback(async () => {
    try {
      const response = await getEmployeeRateDetails("GET", EMPLOYEE_DETAILS(employeePendingLdapLogin));
      setEmployeeDetails(response); //TODO typing for response
      toggleLoading(false);
    } catch (e) {
      toast.error("Fetching of employee details has failed!");
    }
  }, [employeePendingLdapLogin, getEmployeeRateDetails]);

  useEffect(() => {
    getEmployeeDetails();
  }, [getEmployeeDetails]);

  return isLoading || !employee ? (
    <LinearProgress />
  ) : (
    <>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <S.BackButtonWrapper>
            <OutlinedButton onClick={() => history.push("/")} startIcon={<ArrowBackIosIcon />}>
              Back to All Employee Rates
            </OutlinedButton>
          </S.BackButtonWrapper>
          <EmployeeBasicInfo employee={employee} getEmployeeDetails={getEmployeeDetails} />
        </Grid>
        <Grid item xs={9}>
          <EmployeeRatesOverview getEmployeeDetails={getEmployeeDetails} />
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeDetails;
