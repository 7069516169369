import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  GET_ALL_CLIENT_POSITION_CATEGORIES,
  GET_ALL_CLIENTS,
  GET_ALL_CURRENCIES,
  GET_ALL_EMPLOYEES,
  GET_ALL_LOCATIONS,
  GET_ALL_POSITIONS,
} from "router/url";
import { useFetch } from "utils/hooks/useFetch";
import { PaginatedData } from "../modules/MarginList/EmployeeList.types";
import { IDictionary, IGlobalContextValue } from "./types";

export type Props = {
  children: ReactNode;
};

const INITIAL_VALUE: IGlobalContextValue = {
  clientOptions: [],
  currencyOptions: [],
  employeesOptions: [],
  locationOptions: [],
  positionCategoryOptions: [],
  isLoading: true,
  toggleLoading: () => {},
  fromQueryDate: new Date(),
  setFromQueryDate: () => {},
  toQueryDate: new Date(),
  setToQueryDate: () => {},
  clientPositionCategoriesOptions: [],
};

export const GlobalContext = createContext<IGlobalContextValue>(INITIAL_VALUE);

export function GlobalProvider({ children }: Props) {
  const [isLoading, toggleLoading] = useState(true);
  const [employeesOptions, setEmployeesOptions] = useState<IDictionary[]>([]);
  const [clientOptions, setClientOptions] = useState<IDictionary[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<IDictionary[]>([]);
  const [locationOptions, setLocationOptions] = useState<IDictionary[]>([]);
  const [positionCategoryOptions, setPositionCategoryOptions] = useState<string[]>([]);
  const [fromQueryDate, setFromQueryDate] = useState<Date | null>(new Date());
  const [toQueryDate, setToQueryDate] = useState<Date | null>(new Date());
  const [clientPositionCategoriesOptions, setClientPositionCategoriesOptions] = useState([]);

  const getAllClients = useFetch();
  const getAllEmployees = useFetch();
  // TODO: remove (argument) after backend api fix
  const getAllCurrencies = useFetch(true);
  const getAllLocations = useFetch();
  const getAllPositionCategories = useFetch();
  const getClientPositionCategories = useFetch();

  const getDictionaries = useCallback(async () => {
    try {
      const allClients = await getAllClients("GET", GET_ALL_CLIENTS);
      const allEmployees = await getAllEmployees("GET", GET_ALL_EMPLOYEES);
      const allCurrencies = await getAllCurrencies("GET", GET_ALL_CURRENCIES);
      const allLocations = await getAllLocations("GET", GET_ALL_LOCATIONS);
      const allPositionCategories = await getAllPositionCategories("GET", GET_ALL_POSITIONS);
      const allClientPositionCategories = await getClientPositionCategories("GET", GET_ALL_CLIENT_POSITION_CATEGORIES);

      setClientOptions(allClients);
      setEmployeesOptions(allEmployees);
      setCurrencyOptions(allCurrencies);
      setLocationOptions(allLocations);
      setPositionCategoryOptions(allPositionCategories);
      setClientPositionCategoriesOptions(allClientPositionCategories);
    } catch (error) {
      setClientOptions([]);
      setEmployeesOptions([]);
      setCurrencyOptions([]);
      setLocationOptions([]);
      setPositionCategoryOptions([]);
    } finally {
      toggleLoading(false);
    }
  }, [
    getAllClients,
    getAllEmployees,
    getAllCurrencies,
    getAllLocations,
    getAllPositionCategories,
    getClientPositionCategories,
  ]);

  useEffect(() => {
    if (localStorage.getItem("dwhToken")) {
      getDictionaries();
    }
  }, [getDictionaries]);

  const value: IGlobalContextValue = useMemo(
    () => ({
      employeesOptions,
      clientOptions,
      isLoading,
      toggleLoading,
      currencyOptions,
      locationOptions,
      positionCategoryOptions,
      fromQueryDate,
      setFromQueryDate,
      toQueryDate,
      setToQueryDate,
      clientPositionCategoriesOptions,
    }),
    [
      clientOptions,
      employeesOptions,
      isLoading,
      currencyOptions,
      locationOptions,
      toggleLoading,
      positionCategoryOptions,
      fromQueryDate,
      setFromQueryDate,
      toQueryDate,
      setToQueryDate,
      clientPositionCategoriesOptions,
    ]
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
}
