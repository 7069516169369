import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MaterialTable, { Column } from "material-table";
import moment, { Moment } from "moment";
import { CLIENT_RATE_HISTORY, DELETE_CLIENT_RATE, DELETE_EMPLOYEE_RATE, EMPLOYEE_RATE_HISTORY } from "router/url";
import { ContainedButton } from "components/Button";
import DefaultButton from "components/Button/DefaultButton";
import tableIcons from "components/Table/icons";
import ClosingPreviousRatePopup from "modules/MarginList/components/RateForm/ClosingPreviousRatePopup";
import RateForm from "modules/MarginList/components/RateForm/RateForm";
import { employmentsOptions } from "modules/MarginList/constants";
import { IEmployeeRate } from "modules/MarginList/screens/EmployeeDetails/EmployeeDetails.types";
import { currentRowStyle, dateFormat, dateFormatIso, futureRowStyle, pastRowStyle } from "utils/constants";
import { getDate, getDateFormatted, getDateFormattedIso, getDateIso } from "utils/dateFormatter";
import { useFetch } from "utils/hooks/useFetch";
import { useToggle } from "utils/hooks/useToogle";
import { IClientDailyRate } from "../../EmployeeRatesOverview.types";
import ClientRateForm from "../ClientRateForm";
import EmployeeRateHistoryPopup from "modules/MarginList/components/RateForm/EmployeeRateHistoryPopup";

interface Props {
  getEmployeeDetails: () => void;
}
const EmployeeCurrentRates = ({ getEmployeeDetails }: Props) => {
  const employeeRatesColumns: Column<IEmployeeRate>[] = [
    {
      field: "dateFrom",
      title: "Starting date",
    },
    {
      field: "dateTo",
      title: "End date",
      render: (item) => (item.dateTo === "9999-12-31" ? "" : `${item.dateTo}`),
    },
    {
      field: "dailyRate",
      title: "Employee hourly rate",
      render: (item) => `${item.dailyRate / 8} ${item.currency}`,
    },
    {
      field: "dailyRate",
      title: "Employee daily rate",
      render: (item) => `${item.dailyRate} ${item.currency}`,
    },
    {
      field: "monthlyGrossRate",
      title: "Monthly gross rate",
      render: (item) => `${item.monthlyGrossRate} ${item.currency}`,
    },
    {
      field: "monthlyNetRate",
      title: "Monthly net rate",
      render: (item) => `${item.monthlyNetRate} ${item.currency}`,
    },
    {
      field: "formOfEmployment",
      title: "Form of employment",
      render: (item) => employmentsOptions.find((option) => option.value === item.formOfEmployment)?.label,
    },
  ];
  const clientRatesColumns: Column<IClientDailyRate>[] = [
    {
      field: "dateFrom",
      title: "Starting date",
    },
    {
      field: "dateTo",
      title: "End date",
      render: (item) => (item.dateTo === "9999-12-31" ? "" : `${item.dateTo}`),
    },
    {
      field: "client.name",
      title: "Client",
    },
    {
      field: "project.name",
      title: "Project",
    },
    { field: "clientDailyRateInPln", title: "Daily rate PLN" },
    {
      field: "clientDailyRateInOriginalCurrency",
      title: "Original rate",
      render: (item) => `${item.dailyRate} ${item.currency}`,
    },
  ];
  let { employeePendingLdapLogin } = useParams();
  const [, toggleLoading] = useState(true);
  const [employeeRatesHistory, setEmployeeRatesHistory] = useState([]);
  const [clientRatesHistory, setClientRatesHistory] = useState([]);
  const [currentEmployeeRate, setCurrentEmployeeRate] = useState<any>();
  const [currentClientRate, setCurrentClientRate] = useState<any>();
  const [historyRate, setHistoryRate] = useState<any>();

  const getEmployeeRatesHistoryFetch = useFetch();
  const clientRatesHistoryFetch = useFetch();
  const deleteEmployeeRate = useFetch();
  const deleteClientRate = useFetch();

  const getEmployeeRatesHistory = useCallback(async () => {
    try {
      const response = await getEmployeeRatesHistoryFetch("GET", EMPLOYEE_RATE_HISTORY(employeePendingLdapLogin));
      const employeeRates = response.map((item: any) => ({
        ...item,
        employeeDailyRateStartingDate: getDateFormattedIso(item.dateFrom),
      }));
      const currentEmployeeRate = employeeRates.find(
        (rate: any) => rate.dateFrom && (getDateIso(rate.dateFrom) as Moment) <= (getDate(new Date()) as Moment)
      );
      setCurrentEmployeeRate(currentEmployeeRate);
      setEmployeeRatesHistory(employeeRates);
      toggleLoading(false);
    } catch (error) {
      toast.error("Fetching of employee rates has failed!");
    }
  }, [employeePendingLdapLogin, getEmployeeRatesHistoryFetch]);

  const getClientRatesHistory = useCallback(async () => {
    try {
      const response = await clientRatesHistoryFetch("GET", CLIENT_RATE_HISTORY(employeePendingLdapLogin));
      const clientRates = response.map((item: any) => ({
        ...item,
        clientDailyRateStartingDate: getDateFormatted(item.dateFrom),
      }));
      const currentClientRates = clientRates.filter(
        (rate: any) =>
          (getDateIso(rate.dateFrom) as Moment) <= (getDate(new Date()) as Moment) &&
          (!rate.dateTo || (getDateIso(rate.dateTo) as Moment) > (getDate(new Date()) as Moment))
      );

      setCurrentClientRate(currentClientRates);

      setClientRatesHistory(clientRates);

      toggleLoading(false);
    } catch (error) {
      toast.error("Fetching of client rates has failed!");
    }
  }, [clientRatesHistoryFetch, employeePendingLdapLogin]);

  const [openEmployeeRateForm, handleOpenEmployeeRateForm, handleCloseEmployeeRateForm] = useToggle();
  const [openClientRateForm, handleOpenClientRateForm, handleCloseClientRateForm] = useToggle();
  const [openEmployeeRateHistory, handleOpenEmployeeRateHistory, handleCloseEmployeeRateHistory] = useToggle();

  useEffect(() => {
    getEmployeeRatesHistory();
  }, [getEmployeeRatesHistory]);

  useEffect(() => {
    getClientRatesHistory();
  }, [getClientRatesHistory]);

  const [showAllEmployeeRates, toggleShowAllEmployeeRates] = useState(false);
  const [showAllClientRates, toggleShowAllClientRates] = useState(false);
  const [editedDailyRate, setEditedDailyRate] = useState<IClientDailyRate>();
  const [editedEmployeeRate, setEditedEmployeeRate] = useState<any>(); //TODO type
  const [openDailyRate, setOpenDailyRate] = useState<any>();

  const [openPreviousRatePopup, handleOpenPreviousRatePopup, handleClosePreviousRatePopup] = useToggle();
  const onEmployeeRateAdd = () => {
    const openRates = employeeRatesHistory.filter((a: any) => a.dateTo === "9999-12-31");

    if (Array.isArray(openRates) && openRates.length) {
      setOpenDailyRate(openRates[0]);
      handleOpenPreviousRatePopup();
      return;
    }

    setEditedEmployeeRate(undefined);
    openRateForm();
  };

  const openRateForm = () => {
    handleClosePreviousRatePopup();
    handleOpenEmployeeRateForm();
  };

  const onEmployeeRateEdit = (editedItem: any) => {
    // TODO Type
    setEditedEmployeeRate({
      ...editedItem,
    });
    handleOpenEmployeeRateForm();
  };

  const onClientRateAdd = () => {
    setEditedDailyRate(undefined);
    handleOpenClientRateForm();
  };

  const onClientRateEdit = (editedItem: IClientDailyRate) => {
    setEditedDailyRate(editedItem);
    handleOpenClientRateForm();
  };

  const onEmployeeRateRemove = async (rowData: IEmployeeRate) => {
    const confirmed = window.confirm("Do you want to remove this employee rate?");
    if (confirmed) {
      try {
        await deleteEmployeeRate("DELETE", DELETE_EMPLOYEE_RATE(rowData.id));
        getEmployeeDetails();
        getEmployeeRatesHistory();
        toast.success("Employee rate has been removed!");
      } catch (e) {
        toast.error("Employee removal has failed!");
      }
    }
  };

  const onClientRateRemove = async (rowData: IClientDailyRate) => {
    const confirmed = window.confirm("Do you want to remove this client rate?");
    if (confirmed) {
      try {
        await deleteClientRate("DELETE", DELETE_CLIENT_RATE(rowData.id));
        getEmployeeDetails();
        getClientRatesHistory();
        toast.success("Client rate has been removed!");
      } catch (e) {
        toast.error("Client removal has failed!");
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant={"h6"}>Employee rate</Typography>
            <ContainedButton onClick={onEmployeeRateAdd} startIcon={<AddIcon />}>
              Add employee rate
            </ContainedButton>
          </Box>
          {/* TODO seperate component for each table */}
          <MaterialTable
            columns={employeeRatesColumns}
            //TODO eliminate nested ternary
            data={showAllEmployeeRates ? employeeRatesHistory : currentEmployeeRate ? [currentEmployeeRate] : []}
            icons={tableIcons}
            options={{
              paging: false,
              toolbar: false,
              actionsColumnIndex: -1,
              rowStyle: (rowData) => {
                if (rowData.dateFrom === currentEmployeeRate?.dateFrom) {
                  return currentRowStyle as any;
                } else if (
                  moment(rowData.dateFrom, dateFormatIso) > moment(currentEmployeeRate?.dateFrom, dateFormat)
                ) {
                  return futureRowStyle as any;
                } else {
                  return pastRowStyle as any;
                }
              },
            }}
            actions={[
              (rowData) => ({
                icon: () => (
                  <EditIcon
                    style={currentEmployeeRate?.dateFrom === rowData.dateFrom ? { color: "#FFF" } : { color: "#000" }}
                  />
                ),
                tooltip: "Update",
                onClick: (_, rowData) => {
                  onEmployeeRateEdit(rowData as any);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <DeleteIcon
                    style={currentEmployeeRate?.dateFrom === rowData.dateFrom ? { color: "#FFF" } : { color: "#000" }}
                  />
                ),
                tooltip: "Remove",
                onClick: (_, rowData) => {
                  onEmployeeRateRemove(rowData);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <HistoryIcon
                    style={currentEmployeeRate?.dateFrom === rowData.dateFrom ? { color: "#FFF" } : { color: "#000" }}
                  />
                ),
                tooltip: "Show history",
                onClick: (_, rowData) => {
                  setHistoryRate(rowData.id);
                  handleOpenEmployeeRateHistory();
                },
              }),
            ]}
          />
          {employeeRatesHistory.filter((employeeRate: any) => employeeRate?.id !== currentEmployeeRate?.id).length ? (
            <Box mt={1} display="flex" justifyContent="center">
              <DefaultButton
                startIcon={showAllEmployeeRates ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => {
                  toggleShowAllEmployeeRates(!showAllEmployeeRates);
                }}
              >
                Show {showAllEmployeeRates ? "current rate" : "more"}
              </DefaultButton>
            </Box>
          ) : null}
        </Grid>
        <Grid item lg={12}>
          <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant={"h6"}>Client rate</Typography>
            <ContainedButton onClick={onClientRateAdd} startIcon={<AddIcon />}>
              Add client rate
            </ContainedButton>
          </Box>
          <MaterialTable
            columns={clientRatesColumns}
            //TODO eliminate nested ternary
            data={showAllClientRates ? clientRatesHistory : currentClientRate ? currentClientRate : []}
            icons={tableIcons}
            options={{
              paging: false,
              toolbar: false,
              actionsColumnIndex: -1,
              rowStyle: (rowData) => {
                if (
                  (getDateIso(rowData.dateFrom) as Moment) <= (getDate(new Date()) as Moment) &&
                  (rowData.dateTo === "9999-12-31" ||
                    (getDateIso(rowData.dateTo) as Moment) > (getDate(new Date()) as Moment))
                ) {
                  return currentRowStyle as any;
                } else if (getDateIso(rowData.dateFrom)! > getDate(new Date())!) {
                  return futureRowStyle as any;
                } else {
                  return pastRowStyle as any;
                }
              },
            }}
            actions={[
              (rowData) => ({
                icon: () => (
                  <EditIcon
                    style={
                      (getDateIso(rowData.dateFrom) as Moment) <= (getDateIso(new Date()) as Moment) &&
                      (!rowData.dateTo || (getDateIso(rowData.dateTo) as Moment) > (getDateIso(new Date()) as Moment))
                        ? { color: "#FFF" }
                        : { color: "#000" }
                    }
                  />
                ),
                tooltip: "Update",
                onClick: (_, rowData) => {
                  onClientRateEdit(rowData as IClientDailyRate);
                },
              }),
              (rowData) => ({
                icon: () => (
                  <DeleteIcon
                    style={
                      (getDateIso(rowData.dateFrom) as Moment) <= (getDateIso(new Date()) as Moment) &&
                      (!rowData.dateTo || (getDateIso(rowData.dateTo) as Moment) > (getDateIso(new Date()) as Moment))
                        ? { color: "#FFF" }
                        : { color: "#000" }
                    }
                  />
                ),
                tooltip: "Remove",
                onClick: (_, rowData) => {
                  onClientRateRemove(rowData as IClientDailyRate);
                },
              }),
            ]}
          />
          {clientRatesHistory.length - currentClientRate?.length ? (
            <Box mt={1} display="flex" justifyContent="center">
              <DefaultButton
                startIcon={showAllClientRates ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => {
                  toggleShowAllClientRates(!showAllClientRates);
                }}
              >
                Show {showAllClientRates ? "current rate" : "more"}
              </DefaultButton>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      {openEmployeeRateForm && (
        <RateForm
          onClose={handleCloseEmployeeRateForm}
          getEmployeeList={getEmployeeRatesHistory}
          getEmployeeDetails={getEmployeeDetails}
          editedItem={editedEmployeeRate}
        />
      )}
      {openPreviousRatePopup && (
        <ClosingPreviousRatePopup
          setEditedRateForm={setEditedEmployeeRate}
          item={openDailyRate}
          openNextForm={openRateForm}
        />
      )}
      {openClientRateForm && (
        <ClientRateForm
          onClose={handleCloseClientRateForm}
          getEmployeeList={getEmployeeRatesHistory}
          getClientRatesHistory={getClientRatesHistory}
          editedItem={editedDailyRate}
        />
      )}
      {openEmployeeRateHistory && (
        <EmployeeRateHistoryPopup
          rateId={historyRate}
          closeForm={handleCloseEmployeeRateHistory}
          employmentsOptions={employmentsOptions}
        />
      )}
    </>
  );
};

export default EmployeeCurrentRates;
