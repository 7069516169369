import { useCallback, useState } from "react";

type UseToggle = [boolean, () => void, () => void];

export const useToggle = (initState?: boolean): UseToggle => {
  const [open, setOpen] = useState(initState || false);
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  return [open, handleClickOpen, handleClose];
};
