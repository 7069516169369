import { Box, FormControl, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import tableIcons from "components/Table/icons";
import { GlobalContext } from "globalContext/GlobalContext";
import MaterialTable from "material-table";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GET_CUSTOM_REPORTS } from "router/url";
import { useFetch } from "utils/hooks/useFetch";
import SelectedCustomReport from "./SelectedCustomReport";

const CustomReports = () => {
  const fetch = useFetch();
  const { toggleLoading, isLoading } = useContext(GlobalContext);
  const [selectedCustomReport, setSelectedCustomReport] = useState<any>("");
  const [customReports, setCustomReports] = useState<{ id: number; name: string }[]>([]);

  const getAllCustomReports = useCallback(async () => {
    try {
      toggleLoading(true);
      const response = await fetch("GET", GET_CUSTOM_REPORTS);
      toggleLoading(false);
      setCustomReports(response);
      setSelectedCustomReport(response[0]?.name || "");
    } catch (error) {
      toast.error("Custom reports couldn't be loaded. Try again!");
    }
  }, [fetch]);

  const handleChange = (event: any) => {
    setSelectedCustomReport(event.target.value);
  };

  useEffect(() => {
    getAllCustomReports();
  }, []);

  if (isLoading) return null;

  return (
    <div>
      <Paper>
        <Box p={2}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="custom-report-select">Custom report</InputLabel>
            <Select
              native
              value={selectedCustomReport}
              onChange={handleChange}
              inputProps={{
                name: "custom-report",
                id: "custom-report-select",
              }}
            >
              {customReports.map((customReport) => (
                <option key={customReport.name} value={customReport.name}>
                  {customReport.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>

      <Box mt={2}>
        <SelectedCustomReport customReportId={selectedCustomReport}></SelectedCustomReport>
      </Box>
    </div>
  );
};

export default CustomReports;
