import React, { ReactElement } from "react";
import { Box, Button, Dialog, DialogProps, Grid, IconButton, LinearProgress, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as S from "./Modal.css";

interface Props {
  onClose: () => void;
  onSubmit?: () => void;
  isLoading: boolean;
  title: string;
  width?: DialogProps["maxWidth"];
  children: ReactElement;
  closeButtonText?: string;
  submitButtonText?: string;
  hideSubmitButton?: boolean;
}

const Modal = ({
  onClose,
  onSubmit,
  isLoading,
  title,
  width,
  children,
  closeButtonText = "Cancel",
  submitButtonText = "Save",
  hideSubmitButton = false,
}: Props) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth={width}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Box m={2} p={2}>
          <S.DialogHeader>
            <Typography variant={"h6"}>{title}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </S.DialogHeader>
          <Grid item xs={12}>
            {children}
          </Grid>
          <Grid item xs={12}>
            <S.ButtonsWrapper>
              <Button onClick={onClose} variant={"outlined"} color={"default"} type="reset">
                {closeButtonText}
              </Button>
              {!hideSubmitButton && (
                <Button //TODO prevent double-click
                  onClick={onSubmit}
                  variant={"contained"}
                  color={"primary"}
                  type="submit"
                >
                  {submitButtonText}
                </Button>
              )}
            </S.ButtonsWrapper>
          </Grid>
        </Box>
      )}
    </Dialog>
  );
};

export default Modal;
