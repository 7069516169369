import { TextField } from "@material-ui/core";
import { Modal } from "components";
import tableIcons from "components/Table/icons";
import { IDictionary } from "globalContext/types";
import MaterialTable, { Column } from "material-table";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CURRENCY_EXCHANGE_RATE } from "router/url";
import { getDateFormattedIso } from "utils/dateFormatter";
import { useFetch } from "utils/hooks/useFetch";

interface ICurrencyExchangeRateModalProps {
  currencyOptions: IDictionary[];
  currencyExchangeModal: boolean;
  handleCloseCurrencyExchangeModal: () => void;
}

const CurrencyExchangeRateModal = (props: ICurrencyExchangeRateModalProps) => {
  const { currencyOptions, currencyExchangeModal, handleCloseCurrencyExchangeModal } = props;

  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState<any[]>([]);

  const { control, handleSubmit, reset } = useForm<any>();
  const postCheckCurrency = useFetch();

  const columns: Column<any>[] = [
    {
      field: "date",
      title: "Date",
    },
    {
      field: "sourceCurrency",
      title: "Source currency",
    },
    {
      field: "targetCurrency",
      title: "Target currency",
    },
    {
      field: "rate",
      title: "Rate",
      render: (item: any) => {
        return (
          <Controller
            as={TextField}
            control={control}
            name={item.sourceCurrency}
            type="number"
            inputProps={{ step: 0.01 }}
            rules={{ required: true }}
          />
        );
      },
    },
  ];

  const getCurrencyExchangeRates = useCallback(async () => {
    setLoading(true);

    try {
      const currencies = await Promise.all(
        currencyOptions
          .filter((currency) => currency.uid !== "PLN")
          .map((currency) =>
            postCheckCurrency("POST", CURRENCY_EXCHANGE_RATE, {
              targetCurrency: "pln",
              sourceCurrency: currency.uid,
            })
          )
      );
      setCurrencies(currencies);
      setLoading(false);
    } catch (error) {
      toast.error("Margins list couldn't be loaded. Try again!");
    }
  }, [currencyOptions]);

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      await Promise.all(
        Object.entries(data).map(([key, val]) => {
          const foundRate = currencies.find((currency) => currency.sourceCurrency === key);
          if (foundRate.rate !== val) {
            return postCheckCurrency("PUT", CURRENCY_EXCHANGE_RATE, {
              targetCurrency: "pln",
              sourceCurrency: key.toLocaleLowerCase(),
              rate: val,
              date: getDateFormattedIso(new Date()),
            });
          }
        })
      );
      handleCloseCurrencyExchangeModal();
      toast.success("Currency exchange rate has been changed!");
    } catch (e) {
      toast.error("Currency exchange rate has NOT been changed. Try again!");
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  useEffect(() => {
    if (currencyExchangeModal && currencyOptions) {
      getCurrencyExchangeRates();
    }
  }, [currencyExchangeModal, currencyOptions]);

  useEffect(() => {
    reset(
      currencies.reduce((acc, currencyRate) => {
        acc[currencyRate.sourceCurrency] = currencyRate.rate;
        return acc;
      }, {})
    );
  }, [currencies]);

  return (
    <Modal
      onClose={handleCloseCurrencyExchangeModal}
      onSubmit={onSubmit}
      isLoading={loading}
      title={`Currency exchange rates`}
      closeButtonText={`Cancel`}
      width={`md`}
    >
      <MaterialTable
        columns={columns}
        data={currencies}
        icons={tableIcons}
        options={{ paging: false, showTitle: false, search: false, toolbar: false }}
      />
    </Modal>
  );
};

export default CurrencyExchangeRateModal;
