import { Box, IconButton, LinearProgress, Tab, Tabs, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Settings } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { DatePicker } from "@material-ui/pickers";
import { GlobalContext } from "globalContext/GlobalContext";
import { default as React, useCallback, useContext, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { RoutesList } from "router/url";
import { datePickerDateFormat } from "utils/constants";
import SettingsMenu from "../SettingsMenu/SettingsMenu";
import * as S from "./Header.css";

const ROUTES = [RoutesList.Root, RoutesList.Margins, RoutesList.Reports, RoutesList.RatesChanges];

interface Props {
  onLogOut: () => void;
}

const useStyles = makeStyles({
  root: {
    color: "white",
  },
});

export default function Header({ onLogOut }: Props) {
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState<null | HTMLElement>(null);

  const classes = useStyles();

  const { isLoading, fromQueryDate, setFromQueryDate, toQueryDate, setToQueryDate, currencyOptions } =
    useContext(GlobalContext);

  const location = useLocation();
  const history = useHistory();

  const currentLocationIndex = ROUTES.findIndex((route: string): boolean => route === location.pathname);

  const [currentRoute, setCurrentRoute] = useState(currentLocationIndex === -1 ? 0 : currentLocationIndex);

  const handleFromQueryDateChange = useCallback(
    (date): void => {
      if (date && !isNaN(date.getTime())) {
        setFromQueryDate(date);
        if (isIncorrectDateRange(date, toQueryDate)) {
            setToQueryDate(date);
        }
      }
    },
    [setFromQueryDate, setToQueryDate, toQueryDate]
  );

  const handleToQueryDateChange = useCallback(
    (date): void => {
      if (date && !isNaN(date.getTime())) {
        setToQueryDate(date);
      }
    },
    [setToQueryDate]
  );

  const handleOpenSettings = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsMenuAnchor(e.currentTarget);
  }, []);

  const handleCloseSettings = useCallback(() => {
    setSettingsMenuAnchor(null);
  }, []);

  const handleTabChange = useCallback(
    (e: any, newValue: any): void => {
      setCurrentRoute(newValue);
      history.push(ROUTES[newValue]);
    },
    [history, ROUTES]
  );

  const isIncorrectDateRange = (fromDate: Date | null, toDate: Date | null) => {
      if (fromDate && toDate) {
          return new Date(toDate.toDateString()) < new Date(fromDate.toDateString());
      }
      return false;
  }

  return (
    <>
      <Box color="white" display="flex" paddingX="2rem" height="auto" alignItems="center">
        <S.Header>
          <Typography variant="h6" noWrap>
            DWH
          </Typography>
        </S.Header>
        {localStorage.getItem("dwhToken") && (
          <Box
            display="flex"
            flexWrap="wrap-reverse"
            flexDirection="row"
            alignItems="center"
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Box flex={1}>
              <Tabs variant="scrollable" value={currentRoute} onChange={handleTabChange}>
                <Tab label="Employees" />
                <Tab label="Margins" />
                <Tab label="Reports" />
                <Tab label="Rates changes" />
              </Tabs>
            </Box>
            <Box color="secondary.main" display="flex" alignItems="center" flex={1} justifyContent="flex-end">
              <Box marginX={4} minWidth={112} display="flex">
                <DatePicker
                  variant="inline"
                  size="small"
                  InputLabelProps={{ style: { color: "white" } }}
                  InputProps={{
                    classes,
                  }}
                  format={datePickerDateFormat}
                  label="From date"
                  value={fromQueryDate}
                  onChange={handleFromQueryDateChange}
                  required={false}
                />
                <DatePicker
                  variant="inline"
                  size="small"
                  InputLabelProps={{ style: { color: "white" } }}
                  InputProps={{
                    classes,
                  }}
                  format={datePickerDateFormat}
                  label="To date"
                  value={toQueryDate}
                  onChange={handleToQueryDateChange}
                  required={false}
                  shouldDisableDate={(date) => isIncorrectDateRange(fromQueryDate, date)}
                />
              </Box>
              <Tooltip title="Settings">
                <IconButton onClick={handleOpenSettings} classes={classes} aria-label="settings">
                  <Settings />
                </IconButton>
              </Tooltip>
              <Tooltip title="Logout">
                <IconButton onClick={onLogOut} classes={classes} aria-label="logout">
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Box>
      <SettingsMenu
        currencyOptions={currencyOptions}
        handleCloseSettings={handleCloseSettings}
        settingsMenuAnchor={settingsMenuAnchor}
      ></SettingsMenu>
      {isLoading ? <LinearProgress color="primary" /> : <></>}
    </>
  );
}
