import { Link } from "react-router-dom";
import styled from "styled-components";

export const FormGrid = styled.div`
  direction: flex;
  flex-direction: column;
  > * {
    margin: 1rem 0;
    width: 100%;
  }
`;

export const MessageLink = styled(Link)`
  color: white;
  text-decoration: none;
`;
