import React from "react";
import { Box } from "@material-ui/core";
import { Modal } from "components";

interface Props {
  openNextForm: () => void;
  setEditedRateForm: (rate: any) => void;
  item: any;
}

const ClosingPreviousRatePopup = ({ openNextForm, setEditedRateForm, item }: Props) => {
  const openEditingPastRateForm = () => {
    setEditedRateForm({
      ...item,
    });
    openNextForm();
  };

  const openNewRateForm = () => {
    setEditedRateForm(undefined);
    openNextForm();
  };

  return (
    <Modal
      onClose={openNewRateForm}
      onSubmit={openEditingPastRateForm}
      isLoading={false}
      title={`Do you want to close the previous rate?`}
      closeButtonText={`Add new rate`}
      submitButtonText={`Edit previous rate`}
    >
      <Box padding={2}>
        <p>You are adding a rate while the previous rate does not have an end date.</p>
        <p>Do you want to add the end date to the previous rate?</p>
      </Box>
    </Modal>
  );
};

export default ClosingPreviousRatePopup;
