import { Box, Checkbox, Grid, Paper } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { IDictionary } from "globalContext/types";
import MaterialTable, { Column } from "material-table";
import { employmentsOptions } from "modules/MarginList/constants";
import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { getDateMonthIso } from "utils/dateFormatter";
import tableIcons from "../../components/Table/icons";
import { GlobalContext } from "../../globalContext/GlobalContext";
import { EXPORT_RATES_CHANGES, GET_RATES_CHANGES, RoutesList } from "../../router/url";
import { useFetch } from "../../utils/hooks/useFetch";
import * as S from "./RatesChanges.css";

const mapDictionary = (arr: IDictionary[]): any[] =>
  arr.map((entry: IDictionary): any => ({ value: entry.name, label: entry.name }));

export default function RatesChanges(): ReactElement {
  const columns: Column<any>[] = [
    {
      field: "rateType",
      title: "Rate type",
      render: (item) => (item.rateType === "EMPLOYEE_RATE" ? "Employee rate" : "Client rate"),
    },
    {
      field: "revisionType",
      title: "Change type",
      render: (item) =>
        item.revisionType === "ADD" ? "New rate" : item.revisionType === "MOD" ? "Rate modified" : "Rate deleted",
    },
    {
      field: "changeTimestamp",
      title: "Datetime of change",
      render: (item) => new Date(item.changeTimestamp).toLocaleString("pl-PL"),
    },
    {
      field: "author",
      title: "Author",
    },
    {
      field: "newRate",
      title: "Rate",
      render: (item) =>
        item.revisionType === "MOD" && item.newRate !== item.oldRate ? (
          <>
            <Box color={"red"} style={{ textDecorationLine: "line-through", textDecorationStyle: "solid" }}>
              {item.oldRate ?? "unknown"}
            </Box>
            <Box color={"green"}>{item.newRate}</Box>
          </>
        ) : (
          `${item.newRate}`
        ),
    },
    {
      field: "newRateCurrency",
      title: "Currency",
      render: (item) =>
        item.revisionType === "MOD" && item.newRateCurrency !== item.oldRateCurrency ? (
          <>
            <Box color={"red"} style={{ textDecorationLine: "line-through", textDecorationStyle: "solid" }}>
              {item.oldRateCurrency ?? "unknown"}
            </Box>
            <Box color={"green"}>{item.newRateCurrency}</Box>
          </>
        ) : (
          `${item.newRateCurrency}`
        ),
    },
    {
      field: "newDateFrom",
      title: "Date from",
      render: (item) =>
        item.revisionType === "MOD" && item.newDateFrom !== item.oldDateFrom ? (
          <>
            <Box color={"red"} style={{ textDecorationLine: "line-through", textDecorationStyle: "solid" }}>
              {item.oldDateFrom ?? "unknown"}
            </Box>
            <Box color={"green"}>{item.newDateFrom}</Box>
          </>
        ) : (
          `${item.newDateFrom}`
        ),
    },
    {
      field: "newDateTo",
      title: "Date to",
      render: (item) =>
        item.revisionType === "MOD" && item.newDateTo !== item.oldDateTo ? (
          <>
            <Box color={"red"} style={{ textDecorationLine: "line-through", textDecorationStyle: "solid" }}>
              {item.oldDateTo === "9999-12-31" ? "none" : item.oldDateTo ?? "unknown"}
            </Box>
            <Box color={"green"}>{item.newDateTo === "9999-12-31" ? "none" : item.newDateTo}</Box>
          </>
        ) : (
          `${item.newDateTo === "9999-12-31" ? "none" : item.newDateTo}`
        ),
    },
    {
      field: "newFormOfEmployment",
      title: "Form of employment",
      render: (item) =>
        item.revisionType === "MOD" && item.newFormOfEmployment !== item.oldFormOfEmployment ? (
          <>
            <Box color={"red"} style={{ textDecorationLine: "line-through", textDecorationStyle: "solid" }}>
              {employmentsOptions.find((option) => option.value === item.oldFormOfEmployment)?.label ?? "unknown"}
            </Box>
            <Box color={"green"}>
              {employmentsOptions.find((option) => option.value === item.newFormOfEmployment)?.label ?? "none"}
            </Box>
          </>
        ) : (
          `${employmentsOptions.find((option) => option.value === item.newFormOfEmployment)?.label ?? ""}`
        ),
    },
    {
      field: "newStudent",
      title: "Is employee a student?",
      render: (item) =>
        item.revisionType === "MOD" && item.newStudent !== item.oldStudent ? (
          <>
            <Box color={"red"} style={{ textDecorationLine: "line-through", textDecorationStyle: "solid" }}>
              {item.oldStudent === true ? "Yes" : item.oldStudent === false ? "No" : "unknown"}
            </Box>
            <Box color={"green"}>{item.newStudent === true ? "Yes" : item.newStudent === false ? "No" : "none"}</Box>
          </>
        ) : (
          `${item.newStudent === true ? "Yes" : item.newStudent === false ? "No" : ""}`
        ),
    },
    {
      field: "client",
      title: "Client",
    },
    {
      field: "employee",
      title: "Employee",
    },
  ];

  const pageSizeOptions = [5, 10, 20, 50, 100, 200, 500];
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(500);

  const { fromQueryDate, employeesOptions, clientOptions, toQueryDate, toggleLoading } = useContext(GlobalContext);
  const employees = mapDictionary(employeesOptions);
  const clients = mapDictionary(clientOptions);

  const [changes, setChanges] = useState<any[]>([]);

  let history = useHistory();

  const fetchChanges = useFetch();

  let initFromDate = new Date();
  initFromDate.setDate(1);
  const [includeEmployeeRates, setIncludeEmployeeRates] = useState(true);
  const [includeClientRates, setIncludeClientRates] = useState(true);
  const [employeeFilter, setEmployeeFilter] = useState("");
  const [clientFilter, setClientFilter] = useState("");
  const [rateStartDate, setRateStartDate] = useState<Date | null>(new Date());

  const handleRateStartDateChange = useCallback(
    (date): void => {
      if (date && !isNaN(date.getTime())) {
        setRateStartDate(date);
      }
    },
    [setRateStartDate]
  );

  const getChanges = useCallback(async () => {
    toggleLoading(true);
    try {
      const response = await fetchChanges(
        "GET",
        `${GET_RATES_CHANGES}?page=${currentPage}&size=${pageSize}&includeEmployeeRateHistory=${includeEmployeeRates}&includeClientRateHistory=${includeClientRates}&employee=${employeeFilter}${
          clientFilter ? `&client=${clientFilter}` : ""
        }&rateStartDateMonthAndYear=${getDateMonthIso(rateStartDate) || ""}`
      );

      setChanges(response);
      toggleLoading(false);
    } catch (e) {
      toast.error("Changes list couldn't be loaded. Try again or contact support!");
    }
  }, [
    currentPage,
    fetchChanges,
    toggleLoading,
    pageSize,
    includeEmployeeRates,
    includeClientRates,
    employeeFilter,
    clientFilter,
    rateStartDate,
  ]);

  const exportToCsv = useCallback(async () => {
    try {
      const response = await fetchChanges(
        "GET",
        `${EXPORT_RATES_CHANGES}?page=0&size=99999&includeEmployeeRateHistory=${includeEmployeeRates}&includeClientRateHistory=${includeClientRates}&employee=${employeeFilter}${
          clientFilter ? `&client=${clientFilter}` : ""
        }&rateStartDateMonthAndYear=${getDateMonthIso(rateStartDate) || ""}`,
        undefined,
        undefined,
        "blob"
      );

      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.download = "rates-changes.csv";
      link.click();
    } catch (e) {
      toast.error("Changes couldn't be exported. Try again or contact support!");
    }
  }, [
    currentPage,
    fetchChanges,
    toggleLoading,
    pageSize,
    includeEmployeeRates,
    includeClientRates,
    employeeFilter,
    clientFilter,
    rateStartDate,
  ]);

  useEffect(() => {
    getChanges();
  }, [getChanges, fromQueryDate, toQueryDate]);

  return (
    <>
      <S.TopWrapper>
        <Paper style={{ width: "100%", position: "relative" }}>
          <Box p={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box width="100%">
                  Show employee rates:
                  <Checkbox
                    checked={includeEmployeeRates}
                    onChange={(e, val) => {
                      setIncludeEmployeeRates(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box width="100%">
                  Show client rates:
                  <Checkbox
                    checked={includeClientRates}
                    onChange={(e, val) => {
                      setIncludeClientRates(val);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" width="100%" alignItems="center">
                  <Box component="label" mr={1}>
                    Employee:
                  </Box>
                  <Select
                    options={employees}
                    isClearable
                    onChange={(selected) => {
                      if (selected?.value) {
                        setEmployeeFilter(selected.value);
                      } else {
                        setEmployeeFilter("");
                      }
                    }}
                    placeholder="Employee..."
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                      container: (base: any) => ({ ...base, width: "100%", minWidth: 256 }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" width="100%" alignItems="center">
                  <Box component="label" mr={1}>
                    Client:
                  </Box>
                  <Select
                    options={clients}
                    isClearable
                    onChange={(selected) => {
                      if (selected?.value) {
                        setClientFilter(selected.value);
                      } else {
                        setClientFilter("");
                      }
                    }}
                    placeholder="Client..."
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                      container: (base: any) => ({ ...base, width: "100%", minWidth: 256 }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </Box>
              </Grid>

              <Grid item>
                <Box display="flex" width="100%" alignItems="center">
                  <Box component="label" style={{ whiteSpace: "nowrap" }} mr={1}>
                    From rate date:
                  </Box>
                  <DatePicker
                    fullWidth
                    variant="inline"
                    format={"MM.yyyy"}
                    views={["month"]}
                    value={rateStartDate}
                    autoOk
                    onChange={handleRateStartDateChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </S.TopWrapper>
      <MaterialTable
        columns={columns}
        data={changes}
        icons={tableIcons}
        page={currentPage}
        onChangePage={setCurrentPage}
        onChangeRowsPerPage={setPageSize}
        options={{
          emptyRowsWhenPaging: false,
          search: false,
          pageSizeOptions,
          pageSize: pageSize,
          exportButton: {
            csv: true,
            pdf: false,
          },
          exportCsv: (data, columns) => exportToCsv(),
          rowStyle: (rowData: any, index: number) => ({
            backgroundColor: index % 2 ? "#FFF" : "#EEE",
          }),
        }}
        title={"All rates changes"}
        onRowClick={(_, rowData) => {
          if (rowData) {
            history.push(`${RoutesList.EmployeeDetail}/${rowData.employeeUid}`);
          }
        }}
      />
    </>
  );
}
