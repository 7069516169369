import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavLink = styled(Link)`
  text-decoration: none;
  color: white;
  padding: 0 1rem;
`;

export const ActiveNavLink = styled(Link)`
  text-decoration: none;
  color: yellow;
  padding: 0 1rem;
`;

export const Header = styled.div`
  padding-right: 3rem;
  padding-bottom: 3px;
`;
export const EggHeaderTitle = styled.span`
  color: yellow;
`;
