import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const dateFormat = "DD.MM.YYYY";
export const dateFormatIso = "YYYY-MM-DD";
export const dateMonthFormatIso = "yyyy-MM";
export const datetimeFormatIso = "YYYY-MM-DD HH:MM:SS";
export const datePickerDateFormat = "dd.MM.yyyy";

export const futureRowStyle: CSSProperties = {
  backgroundColor: "#BBB",
  color: "#FFF",
};

export const currentRowStyle: CSSProperties = {
  backgroundColor: "#000",
  color: "#FFF",
};

export const pastRowStyle: CSSProperties = {
  backgroundColor: "#EEE",
  color: "#000",
};
