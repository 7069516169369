import { useCallback, useContext } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { GlobalContext } from "globalContext/GlobalContext";
import queryString from "query-string";

const formatDateToUrl = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${month > 9 ? "" : "0"}${month}-${day > 9 ? "" : "0"}${day}`;
};

const generateDateQueryParam = (fromDate: Date | null, toDate: Date | null, isSingleDate?: boolean) => {
  if (isSingleDate && fromDate) {
    return { date: formatDateToUrl(fromDate) };
  }
  if (!isSingleDate && fromDate && toDate) {
    return { from: formatDateToUrl(fromDate), to: formatDateToUrl(toDate) };
  }
  return {};
};

export const useFetch = (singleDate?: boolean) => {
  const { fromQueryDate, toQueryDate } = useContext(GlobalContext);

  return useCallback(
    (
      method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "DOWNLOAD",
      url: string,
      data?: {},
      headers?: {},
      responseType?: any
    ) => {
      let response: any;
      const authHeader = {
        Authorization: `Bearer ${localStorage.getItem("dwhToken")}`,
      };
      switch (method) {
        case "GET":
          const [path, search] = url.split("?");
          const oldSearch = search ? queryString.parse(search) : {};
          const datePart = generateDateQueryParam(fromQueryDate, toQueryDate, singleDate);

          const newSearchString = queryString.stringify({
            ...oldSearch,
            ...datePart,
          });
          const newUrl = [path, newSearchString].join("?");
          response = axios.get(newUrl, {
            headers: { ...authHeader, ...headers },
            responseType: responseType ? responseType : undefined,
          });
          break;
        case "POST":
          response = axios.post(url, data, {
            headers: { ...authHeader, ...headers },
          });
          break;
        case "PUT":
          response = axios.put(url, data, {
            headers: { ...authHeader, ...headers },
          });
          break;
        case "PATCH":
          response = axios.patch(url, data, {
            headers: { ...authHeader, ...headers },
          });
          break;
        case "DELETE":
          response = axios.delete(url, {
            headers: { ...authHeader, ...headers },
          });
          break;
      }
      return response
        .then(({ data }: AxiosResponse<any>) => {
          return data;
        })
        .catch((e: AxiosError) => {
          const { response } = e;
          if (response?.status === 401) {
            localStorage.removeItem("dwhToken");
            window.location.reload();
          }
          throw new Error(e.message);
        });
    },
    [fromQueryDate, toQueryDate]
  );
};
