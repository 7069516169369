const MONTHS_IN_A_YEAR = 12;

const monthToYearFormatter = (numberOfMonths: number | null | undefined): string => {
  if (!numberOfMonths) return "0";
  const years = Math.floor(numberOfMonths / MONTHS_IN_A_YEAR);
  const months = numberOfMonths % MONTHS_IN_A_YEAR;
  return `${years ? years : ""}${years ? "y " : ""}${months}m`;
};

export default monthToYearFormatter;
