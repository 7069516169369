import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  > :first-child {
    margin-right: 2rem;
  }
`;
export const ProfilePicture = styled.div`
  &,
  & > img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    color: white;
    > span {
    }
  }
`;

export const Label = styled.span`
  font-weight: bold;
`;

export const DisabledSpan = styled.span`
  font-style: italic;
  opacity: 0.5;
`;
