import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { Grid, InputLabel, TextField, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { GlobalContext } from "globalContext/GlobalContext";
import { UPDATE_EMPLOYEE_CLIENT_DATA } from "router/url";
import { Modal } from "components";
import { useFetch } from "utils/hooks/useFetch";
import monthToYearFormatter from "utils/monthToYearFormatter";
import { IEmployee } from "../../EmployeeDetails.types";
import * as S from "./EmployeeBasicInfo.css";

interface Props {
  employee: IEmployee;
  getEmployeeDetails: () => void;
}

const EmployeeBasicInfo = ({ employee, getEmployeeDetails }: Props) => {
  const profilePicture = employee.imageId ? (
    <img alt="404" src={`https://wejit-test.jit.team/api/image/${employee.imageId}`} />
  ) : (
    <>
      {employee.name.charAt(0)}
      {employee.surname.charAt(0)}
    </>
  );

  const [isClientDataModalOpen, setClientDataModalOpen] = useState(false);
  const { control, handleSubmit } = useForm();
  const toggleModal = () => {
    setClientDataModalOpen(!isClientDataModalOpen);
  };

  const { clientPositionCategoriesOptions } = useContext(GlobalContext);
  const patchClientData = useFetch();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const payload = {
        clientIdentification: data.clientIdentification,
        clientPositionCategoryId: data.clientPositionCategory.value,
      };

      await patchClientData("PATCH", UPDATE_EMPLOYEE_CLIENT_DATA(employee.id), payload);
      getEmployeeDetails();
      toggleModal();
      toast.success("Client rate has been saved!");
    } catch (e) {
      toast.error("Client rate has NOT been saved. Try again!");
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  const placeholderIfUndefined = (s: any, placeholder: any) => {
    if (s) {
      return s;
    } else {
      return placeholder;
    }
  };

  return (
    <>
      <S.HeaderContainer>
        <S.ProfilePicture>{profilePicture}</S.ProfilePicture>
        <Typography variant="h5" style={{ marginRight: "1rem" }}>
          {employee.name} {employee.surname}
        </Typography>
        <Typography align="right">
          <EditIcon onClick={toggleModal} />
        </Typography>
      </S.HeaderContainer>
      <p>
        <S.Label>Is under 26 years old?</S.Label> {employee.isUnder26YearsOld ? "Yes" : "No"}
      </p>
      <p>
        <S.Label>IT experience: </S.Label>
        {employee.monthsOfItExperience ? (
          monthToYearFormatter(employee.monthsOfItExperience)
        ) : (
          <S.DisabledSpan>no data provided</S.DisabledSpan>
        )}
      </p>
      <p>
        <S.Label>Team: </S.Label>
        {employee.team}
      </p>
      <p>
        <S.Label>Position category: </S.Label>
        {employee.positionCategory}
      </p>
      <p>
        <S.Label>Active: </S.Label>
        {employee.activeEmployee ? "Yes" : "No"}
      </p>
      {employee.endOfPartnershipDate ? (
        <p>
          <S.Label>End of partnership: </S.Label>
          {employee.endOfPartnershipDate}
        </p>
      ) : null}
      <p>
        <S.Label>In Jit since: </S.Label>
        {employee.employedSince}
      </p>
      <p>
        <S.Label>Client position category: </S.Label>
        {`${placeholderIfUndefined(employee?.clientPositionCategory?.client?.name, "")} ${placeholderIfUndefined(
          employee?.clientPositionCategory?.location,
          ""
        )} - ${placeholderIfUndefined(employee?.clientPositionCategory?.name, "")}`}
        {employee?.clientPositionCategory?.seniority &&
          ` - ${placeholderIfUndefined(employee?.clientPositionCategory?.seniority, "")}`}
      </p>
      <p>
        <S.Label>Client max rate: </S.Label>
        {`${placeholderIfUndefined(employee?.clientPositionCategory?.encryptedMaxRate, "-")} ${placeholderIfUndefined(
          employee?.clientPositionCategory?.maxRateCurrency,
          ""
        )}`}
      </p>
      <p>
        <S.Label>Client identification: </S.Label>
        {`${placeholderIfUndefined(employee?.clientIdentification, "- ")}`}
      </p>
      {isClientDataModalOpen && (
        <Modal onClose={toggleModal} isLoading={false} onSubmit={onSubmit} title="Edit employee client data">
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  as={TextField}
                  control={control}
                  name="clientIdentification"
                  fullWidth={true}
                  label="Client identification number"
                  rules={{ required: false }}
                  defaultValue={employee.clientIdentification}
                />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <InputLabel>Client position category</InputLabel>
                <Controller
                  as={<Select />}
                  name="clientPositionCategory"
                  control={control}
                  label="Client position category"
                  defaultValue={() => {
                    var defaultValue = clientPositionCategoriesOptions.find(
                      (a: any) => a.uid === employee.clientPositionCategory?.id
                    );
                    return { label: defaultValue?.name, value: defaultValue?.uid };
                  }}
                  options={clientPositionCategoriesOptions.map((a: { name: any; uid: any }) => {
                    return { label: a.name, value: a.uid };
                  })}
                  rules={{ required: false }}
                  styles={{
                    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                />
              </Grid>
            </Grid>
          </>
        </Modal>
      )}
    </>
  );
};

export default EmployeeBasicInfo;
