import React, { ChangeEvent } from "react";
import { Control, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";
import { ErrorLabel } from "components/index.css";

interface Props {
  control: Control;
  name: string;
  label: string;
  disabled?: boolean;
  onChange?: ([e]: ChangeEvent<HTMLInputElement>[]) => ChangeEvent<HTMLInputElement>;
  rules?: {};
  required?: boolean;
  errors: any; //TODO typing
}

const NumberInput = (props: Props) => {
  return (
    <>
      <Controller
        as={
          <NumberFormat
            style={{ width: "100%" }}
            customInput={TextField}
            allowedDecimalSeparators={[".", ","]}
            allowNegative={false}
            decimalScale={2}
          />
        }
        control={props.control}
        name={props.name}
        label={props.label}
        disabled={props.disabled}
        onChange={props.onChange}
        rules={props.required ? { required: true } : {}}
      />
      <ErrorLabel>{props.errors[props.name] && "Incorrect value"}</ErrorLabel>
    </>
  );
};

export default NumberInput;
