import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Controller, EventFunction, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Box, InputLabel, TextField } from "@material-ui/core";
import { GlobalContext } from "globalContext/GlobalContext";
import moment from "moment";
import { ADD_CLIENT_RATE, UPDATE_CLIENT_RATE } from "router/url";
import { KeyboardDatepickerInput, Modal } from "components";
import { ErrorLabel } from "components/index.css";
import { IModal } from "components/Modal/Modal.types";
import { datePickerDateFormat } from "utils/constants";
import { getDateFormatted } from "utils/dateFormatter";
import { useFetch } from "utils/hooks/useFetch";
import { IClientDailyRate } from "../../EmployeeRatesOverview.types";

interface Props extends IModal {
  getClientRatesHistory: () => void;
  getEmployeeList: () => void;
  editedItem?: IClientDailyRate;
}

const ClientRateForm = ({ onClose, getClientRatesHistory, getEmployeeList, editedItem = undefined }: Props) => {
  const { employeePendingLdapLogin } = useParams() as any;
  const [isLoading] = useState(false);
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  const { control, errors, handleSubmit, reset } = useForm<IClientDailyRate>();
  const postNewClientRate = useFetch();
  const patchClientRate = useFetch();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const handleStartDateChange = (date: any) => {
    setSelectedStartDate(date);
  };
  const handleEndDateChange = (date: any) => {
    setSelectedEndDate(date);
  };
  const dictionaryMap = (arr: any[]) => arr.map((x) => ({ value: x.uid, label: x.name }));
  const globalContext = useContext(GlobalContext);
  const currencyOptions = globalContext!.currencyOptions.map((el) => ({ label: el.name, value: el.name }));
  const clientOptions = globalContext!.clientOptions;
  const clients = dictionaryMap(clientOptions);
  const [selectedCurrency, setSelectedCurrency] = useState("PLN");

  const [clientRate, setClientRate] = useState(0);

  const onRateChange = ([e]: ChangeEvent<HTMLInputElement>[]) => {
    const value = e.target.value;
    const valueNumber = Number(value);
    const name = e.target.name;
    setClientRate(valueNumber);
    reset({ [name]: valueNumber });
  };

  // const delayedRate = useDebounce(clientRate, 500);
  // const [rateInPln, setRateInPln] = useState("")

  // const getRateInPln = useCallback(async () => {
  //   if (delayedRate === 0) {
  //     setRateInPln("0 PLN")
  //     return;
  //   }

  //   const payload = {
  //     sourceCurrency: selectedCurrency,
  //     amount: delayedRate,
  //     date: getDateFormatted(selectedStartDate)
  //   }

  //   try {
  //     const plns = await postCurrencyExchange(
  //       "POST",
  //       EXCHANGE_CURRENCY_TO_PLN,
  //       payload)
  //     setRateInPln(`${plns} PLN`)
  //   } catch (e) {
  //     toast.error("Could not fetch exchanged rate.");
  //     setRateInPln(`not available PLN`)
  //   }

  // }, [delayedRate, selectedCurrency, selectedStartDate])

  // useEffect(() => {
  //   getRateInPln();
  // }, [getRateInPln]);

  useEffect(() => {
    if (editedItem && !isInitiallyLoaded) {
      reset({
        clientDailyRate: editedItem.dailyRate,
        currency: currencyOptions.find((el) => el.value === editedItem.currency),
        client: clients.find((el) => el.value === editedItem.client?.uid),
      });
      setSelectedCurrency(editedItem.originalCurrency);
      setSelectedStartDate(moment(editedItem.dateFrom, "YYYY-MM-DD").toDate());
      setSelectedEndDate(
        editedItem.dateTo && editedItem.dateTo !== "9999-12-31"
          ? moment(editedItem.dateTo, "YYYY-MM-DD").toDate()
          : null
      );
      setClientRate(editedItem.dailyRate);
      setIsInitiallyLoaded(true);
    }
  }, [clients, currencyOptions, editedItem, reset, isInitiallyLoaded]);

  const onCurrencyChange: EventFunction = ([selected]) => {
    reset({ currency: selected });
    setSelectedCurrency(selected.value);
  };

  const onSubmit = handleSubmit(async (data: IClientDailyRate) => {
    const endDate = data.clientDailyRateEndDate
      ? { clientDailyRateEndDate: getDateFormatted(data.clientDailyRateEndDate) }
      : {};

    try {
      const payload = {
        clientDailyRateStartingDate: getDateFormatted(data.clientDailyRateStartingDate),
        ...endDate,
        clientDailyRate: data.clientDailyRate,
        employeePendingLdapLogin: employeePendingLdapLogin,
        currency: data.currency.value,
        projectUid: data.project?.value,
        clientUid: data.client.value,
      };
      if (editedItem) {
        await patchClientRate("PATCH", UPDATE_CLIENT_RATE(editedItem.id), payload);
      } else {
        await postNewClientRate("POST", ADD_CLIENT_RATE, payload);
      }
      getClientRatesHistory();
      getEmployeeList();
      onClose();
      toast.success("Client rate has been saved!");
    } catch (e) {
      toast.error("Client rate has NOT been saved. Try again!");
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  return (
    <Modal
      onClose={onClose}
      onSubmit={onSubmit}
      title={`${editedItem ? "Update" : "Add"} client rate`}
      isLoading={isLoading}
      width="sm"
    >
      <>
        <Box display="flex" justifyContent="space-evenly" alignItems="center">
          <Box width="30%">
            <Controller
              as={TextField}
              control={control}
              name="clientDailyRate"
              type="number"
              label="Daily rate"
              rules={{ required: true }}
              onChange={onRateChange}
              value={clientRate}
            />
            {errors.clientDailyRate && <ErrorLabel>This field is required</ErrorLabel>}
          </Box>
          <Box width="30%">
            <Controller
              as={<Select />}
              name="currency"
              control={control}
              label="Currency"
              options={currencyOptions}
              rules={{ required: false }}
              defaultValue={currencyOptions.find((el) => el.label === "PLN")}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
              onChange={onCurrencyChange}
              value={selectedCurrency}
            />
            {errors.currency && <ErrorLabel>This field is required</ErrorLabel>}
          </Box>
          {/* <Box width="30%" color="text.secondary">
            {rateInPln}
          </Box> */}
        </Box>
        <Box display="flex" justifyContent="space-evenly" alignItems="center" mt="1rem">
          <Box width="45%">
            <KeyboardDatepickerInput
              format={datePickerDateFormat}
              margin="normal"
              control={control}
              name="clientDailyRateStartingDate"
              label="Starting date"
              onChange={handleStartDateChange}
              value={selectedStartDate}
              errors={errors}
              required
            />
          </Box>
          <Box width="45%">
            <KeyboardDatepickerInput
              format={datePickerDateFormat}
              margin="normal"
              control={control}
              name="clientDailyRateEndDate"
              label="End date"
              onChange={handleEndDateChange}
              value={selectedEndDate}
              errors={errors}
              minDate={selectedStartDate}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-evenly" alignItems="center" mt="2rem">
          <Box width="90%">
            <InputLabel>Client</InputLabel>
            <Controller
              as={<Select />}
              name="client"
              control={control}
              label="Client"
              options={clients}
              rules={{ required: false }}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              }}
              menuPortalTarget={document.body}
            />
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default ClientRateForm;
