import { ISelectOption } from "./components/RateForm/RateForm.types";

export enum EmploymentForm {
  B2B = "B2B",
  CIVIL_CONTRACT = "CIVIL_CONTRACT",
  CONTRACT_OF_EMPLOYMENT = "CONTRACT_OF_EMPLOYMENT",
}

export const employmentsOptions: ISelectOption[] = [
  {
    value: "B2B",
    label: "B2B",
  },
  {
    value: "CIVIL_CONTRACT",
    label: "Civil contract",
  },
  {
    value: "CONTRACT_OF_EMPLOYMENT",
    label: "Contract of employment",
  },
];

export const discountTypes: ISelectOption[] = [
  {
    value: "SINGLE_EMPLOYEE",
    label: "Single employee discount",
  },
  {
    value: "FIRST_MONTH",
    label: "First month discount",
  },
  {
    value: "VOLUME",
    label: "Volume discount",
  },
];

export const marginsAPISortParamsMap = {
  fullName: "name",
  positionCategory: "positionCategory",
  monthsOfItExperience: "monthsOfItExperience",
  employeeRateDateFrom: "employeeRateDateFrom",
  currency: "currency",
  client: "client",
  team: "team",
  formOfEmployment: "formOfEmployment",
  employedSince: "employedSince",
  location: "location",
  endOfPartnershipDate: "endOfPartnershipDate",
  active: "active",
};
