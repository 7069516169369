import React, { ReactNode, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box } from "@material-ui/core";
import { loginRequest } from "authConfig";
import { GlobalProvider } from "globalContext/GlobalContext";
import Header from "./components/Header/Header";

interface Props {
  children: ReactNode;
}

const AuthManager = (props: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [token, setToken] = useState(localStorage.getItem("dwhToken"));

  useEffect(() => {
    if (localStorage.getItem("dwhToken")) {
      return;
    }

    if (isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(({ accessToken }) => {
          localStorage.setItem("dwhToken", accessToken);
          setToken(accessToken);
        })
        .catch((err) => {
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (err.name === "InteractionRequiredAuthError") {
            return instance
              .acquireTokenPopup({
                ...loginRequest,
                account: accounts[0],
              })
              .then((response) => {
                localStorage.setItem("dwhToken", response.accessToken);
                setToken(response.accessToken);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        });
    } else {
      instance.loginRedirect(loginRequest).catch((e) => console.error(e));
    }
  }, [accounts, instance, isAuthenticated]);

  const logOut = () => {
    localStorage.removeItem("dwhToken");
    const logoutRequest = {
      account: accounts[0],
    };
    instance.logoutRedirect(logoutRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <>
      {isAuthenticated && token ? (
        <GlobalProvider>
          <Box display="flex" minHeight="100vh" flexDirection="column">
            <Box bgcolor="black" position="sticky">
              <Header onLogOut={logOut} />
            </Box>
            <Box overflow="auto" paddingX="2rem" paddingY="1rem">
              {props.children}
            </Box>
          </Box>
        </GlobalProvider>
      ) : (
        <> </>
      )}
    </>
  );
};

export default AuthManager;
