import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { IDictionary } from "globalContext/types";
import MaterialTable, { Column } from "material-table";
import { useHandleChangeEvent } from "utils/hooks/useHandleChangeEvent";
import tableIcons from "../../components/Table/icons";
import { GlobalContext } from "../../globalContext/GlobalContext";
import { GET_EMPLOYEES, GET_EMPLOYEES_CSV, RoutesList } from "../../router/url";
import { useFetch } from "../../utils/hooks/useFetch";
import { PaginatedData } from "../MarginList/EmployeeList.types";
import * as S from "./Employees.css";
import { downloadFile } from "utils/downloadFile";
import { employeeListCSVAPISortParamsMap } from "./constants";

const sortState = { field: "", direction: "asc" };

const Employees = () => {
  const columns: Column<any>[] = [
    {
      field: "name",
      title: "Name",
    },
    {
      field: "surname",
      title: "Surname",
    },
    {
      field: "team",
      title: "Team",
    },
    {
      field: "employedSince",
      title: "Employed Since",
    },
    {
      field: "position",
      title: "Position",
    },
    {
      field: "positionCategory",
      title: "Position Category",
    },
    {
      field: "location",
      title: "Location",
    },
  ];

  const pageSizeOptions = [5, 10, 20, 50, 100, 200, 500, 1000];
  const [currentPage, setCurrentPage] = useState(0);
  const [reportResponse, setReportResponse] = useState<PaginatedData<any>>();
  const [pageSize, setPageSize] = useState(1000);

  const { fromQueryDate, toQueryDate, toggleLoading } = useContext(GlobalContext);

  const [reportData, setReportData] = useState<any[]>([]);
  const [search, setSearch] = useHandleChangeEvent();

  interface IFilterEntry {
    value: string;
    label: string;
  }

  const [filters, setFilters] = useState<{
    team: IFilterEntry[];
    positionCategory: IFilterEntry[];
    project: IFilterEntry[];
    account: IFilterEntry[];
    location: IFilterEntry[];
  }>({
    team: [],
    positionCategory: [],
    project: [],
    account: [],
    location: [],
  });

  const selectQuery = (type: "team" | "positionCategory" | "location") =>
    filters[type].length > 0 ? `${type}=${filters[type].map((x) => x.label).join(",")}&` : "";

  const teamQuery = selectQuery("team");
  const positionQuery = selectQuery("positionCategory");
  const locationQuery = selectQuery("location");

  let history = useHistory();

  const fetchReport = useFetch();
  const { locationOptions } = useContext(GlobalContext)!;
  const mapDictionary = (arr: IDictionary[]): IFilterEntry[] =>
    arr.map((entry: IDictionary): IFilterEntry => ({ value: entry.uid, label: entry.name }));
  const locations = mapDictionary(locationOptions);

  const getReport = useCallback(async () => {
    toggleLoading(true);
    try {
      const response: PaginatedData<any> = await fetchReport(
        "GET",
        `${GET_EMPLOYEES}?page=${currentPage}&size=${pageSize}&active=true&search=${
          search ? search : ""
        }${teamQuery}${positionQuery}${locationQuery}`
      );

      setReportData(response.content);
      setReportResponse(response);
      toggleLoading(false);
    } catch (e) {
      toast.error("List couldn't be loaded. Try again or contact support!");
    }
  }, [currentPage, fetchReport, toggleLoading, pageSize, search, teamQuery, positionQuery, locationQuery]);

  useEffect(() => {
    getReport();
  }, [getReport, fromQueryDate, toQueryDate]);

  const onFilterChange = (filter: string) => (value: any) => {
    return setFilters((filters) => {
      const newFilters = {
        ...filters,
        ...(value ? { [filter]: [...value] } : { [filter]: [] }),
      };
      return newFilters;
    });
  };

  return (
    <>
      <S.TopWrapper>
        <S.SearchPaper>
          <InputBase
            placeholder="Name and/or surname"
            name="search"
            fullWidth={false}
            value={search}
            onChange={setSearch}
          />
          <IconButton type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
        </S.SearchPaper>
        {/* <Box width="10%">
          <Select
            name="location"
            isMulti
            placeholder="Location..."
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={onFilterChange("location")}
            value={filters.location}
            options={locations}
          />
        </Box> */}
      </S.TopWrapper>

      <MaterialTable
        columns={columns}
        data={reportData}
        icons={tableIcons}
        page={currentPage}
        totalCount={reportResponse?.totalElements}
        onChangePage={setCurrentPage}
        onChangeRowsPerPage={setPageSize}
        options={{
          emptyRowsWhenPaging: false,
          search: false,
          pageSizeOptions,
          pageSize: reportResponse?.size || pageSize,
          exportButton: {
            pdf: false,
            csv: true,
          },
          exportCsv: async (columns, data) => {
            const response = await fetchReport(
              "GET",
              `${GET_EMPLOYEES_CSV}?active=true&sort=${employeeListCSVAPISortParamsMap[sortState.field] || ""},${
                sortState.direction
              }&search=${search ? search : ""}${teamQuery}${positionQuery}${locationQuery}`,
              {},
              undefined,
              "blob"
            );
            downloadFile(response, "Employees");
          },
          rowStyle: (rowData: any, index: number) => ({
            backgroundColor: index % 2 ? "#FFF" : "#EEE",
          }),
        }}
        title={""}
        onOrderChange={(orderBy, orderDirection) => {
          sortState.field = (columns[orderBy]?.field as string) || "";
          sortState.direction = orderDirection;
        }}
        onRowClick={(_, rowData) => {
          if (rowData) {
            history.push(`${RoutesList.EmployeeDetail}/${rowData.uid}`);
          }
        }}
      />
    </>
  );
};

export default Employees;
