import { Box, Grid } from "@material-ui/core";
import { Modal } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useFetch } from "utils/hooks/useFetch";
import { toast } from "react-toastify";
import { EMPLOYEE_RATE_EDIT_HISTORY } from "router/url";
import MaterialTable, { Column } from "material-table";
import { getDateFormatted, getDatetimeFormattedIso } from "utils/dateFormatter";

interface Props {
  closeForm: () => void;
  rateId: any;
  employmentsOptions: any;
}

const EmployeeRateHistoryPopup = ({ rateId, closeForm, employmentsOptions }: Props) => {
  const getRatesHistoryFetch = useFetch();
  const [history, setHistory] = useState([]);

  const getRateHistory = useCallback(async () => {
    try {
      const response = await getRatesHistoryFetch("GET", EMPLOYEE_RATE_EDIT_HISTORY(rateId));
      setHistory(response);
    } catch (error) {
      toast.error("Fetching of rate history has failed!");
    }
  }, [rateId, getRatesHistoryFetch]);

  useEffect(() => {
    getRateHistory();
  }, [getRateHistory]);

  const employeeRatesColumns: Column<any>[] = [
    { field: "revInfo.author", title: "Author" },
    {
      field: "revInfo.timestamp",
      title: "Datetime of change",
      render: (item) => new Date(item.revInfo.timestamp).toLocaleString("pl-PL"),
    },
    {
      field: "rate.dateFrom",
      title: "Starting date",
    },
    {
      field: "rate.dateTo",
      title: "End date",
      render: (item) => (item.rate.dateTo === "9999-12-31" ? "-" : `${item.rate.dateTo}`),
    },
    { field: "rate.dailyRate", title: "Employee daily rate" },
    { field: "rate.monthlyGrossRate", title: "Monthly gross rate" },
    { field: "rate.monthlyNetRate", title: "Monthly net rate" },
    {
      field: "rate.formOfEmployment",
      title: "Form of employment",
      render: (item) =>
        employmentsOptions.find((option: { value: any }) => option.value === item.rate.formOfEmployment)?.label,
    },
  ];

  return (
    <Modal
      onClose={closeForm}
      isLoading={false}
      title={`Employee rate history`}
      closeButtonText={`Close`}
      hideSubmitButton
      width={`lg`}
    >
      <Box padding={2}>
        <MaterialTable
          columns={employeeRatesColumns}
          data={history}
          options={{
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
          }}
        />
      </Box>
    </Modal>
  );
};

export default EmployeeRateHistoryPopup;
