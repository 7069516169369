import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import MaterialTable, { Column } from "material-table";
import tableIcons from "../../components/Table/icons";
import { GlobalContext } from "../../globalContext/GlobalContext";
import { GET_ALL_LATEST_ACCOUNTS, RoutesList } from "../../router/url";
import { useFetch } from "../../utils/hooks/useFetch";
import { PaginatedData } from "../MarginList/EmployeeList.types";
import { Warning } from "./types";

const LatestAccounts = () => {
  const columns: Column<any>[] = [
    {
      field: "employeeName",
      title: "Employee",
    },
    {
      field: "clientName",
      title: "Client",
    },
    {
      field: "firstDate",
      title: "In account since",
    },
    {
      field: "clientDailyRate",
      title: "Client daily rate",
      render: (v) => `${v.clientDailyRate} ${v.currency}`,
    },
    {
      field: "clientDailyRatePln",
      title: "Client daily rate PLN",
      render: (v) => `${v.clientDailyRatePln} PLN`,
    },
    {
      field: "clientRateDateFrom",
      title: "Client daily rate start",
    },
    {
      field: "clientRateDateTo",
      title: "Client daily rate end",
    },
    {
      field: "employeeDailyRate",
      title: "Employee daily rate",
      render: (v) => `${v.employeeDailyRate} PLN`,
    },
    {
      field: "employeeRateDateFrom",
      title: "Employee daily rate start",
    },
    {
      field: "employeeRateDateTo",
      title: "Employee daily rate end",
    },
  ];

  const pageSizeOptions = [5, 10, 20, 50, 100, 200, 500, 1000];
  const [currentPage, setCurrentPage] = useState(0);
  const [reportResponse, setReportResponse] = useState<PaginatedData<any>>();
  const [pageSize, setPageSize] = useState(1000);

  const { fromQueryDate, toQueryDate, toggleLoading } = useContext(GlobalContext);

  const [reportData, setReportData] = useState<any[]>([]);

  let history = useHistory();

  const fetchReport = useFetch();

  const getReport = useCallback(async () => {
    toggleLoading(true);
    try {
      const response: PaginatedData<Warning> = await fetchReport(
        "GET",
        `${GET_ALL_LATEST_ACCOUNTS}?page=${currentPage}&size=${pageSize}`
      );

      setReportData(response.content);
      setReportResponse(response);
      toggleLoading(false);
    } catch (e) {
      toast.error("Report couldn't be loaded. Try again or contact support!");
    }
  }, [currentPage, fetchReport, toggleLoading, pageSize]);

  useEffect(() => {
    getReport();
  }, [getReport, fromQueryDate, toQueryDate]);

  return (
    <MaterialTable
      columns={columns}
      data={reportData}
      icons={tableIcons}
      page={currentPage}
      totalCount={reportResponse?.totalElements}
      onChangePage={setCurrentPage}
      onChangeRowsPerPage={setPageSize}
      options={{
        emptyRowsWhenPaging: false,
        exportButton: true,
        exportFileName: "LatestAccounts",
        search: false,
        pageSizeOptions,
        pageSize: reportResponse?.size || pageSize,
        rowStyle: (rowData: any, index: number) => ({
          backgroundColor: index % 2 ? "#FFF" : "#EEE",
        }),
      }}
      title={"Latest accounts with current rates and dates since in account"}
      onRowClick={(_, rowData) => {
        if (rowData) {
          history.push(`${RoutesList.EmployeeDetail}/${rowData.uid}`);
        }
      }}
    />
  );
};

export default LatestAccounts;
