import { Box, Tabs, Tab } from "@material-ui/core";
import ClientMarginsList from "modules/ClientMargins";
import CustomReports from "modules/CustomReports/CustomReports";
import LatestAccounts from "modules/LatestAccounts/LatestAccounts";
import PositionCategoryMarginsList from "modules/PositionCategoryMargins";
import React, { useCallback, useState } from "react";

const Reports = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = useCallback((e: any, newValue: any): void => {
    setCurrentTab(newValue);
  }, []);
  return (
    <Box>
      <Box marginBottom={2}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Clients" />
          <Tab label="Latest accounts" />
          <Tab label="Position categories" />
          <Tab label="Custom reports" />
        </Tabs>
      </Box>

      {currentTab === 0 && <ClientMarginsList></ClientMarginsList>}
      {currentTab === 1 && <LatestAccounts></LatestAccounts>}
      {currentTab === 2 && <PositionCategoryMarginsList></PositionCategoryMarginsList>}
      {currentTab === 3 && <CustomReports></CustomReports>}
    </Box>
  );
};

export default Reports;
