import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Box, Checkbox, Drawer, IconButton, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { GlobalContext } from "globalContext/GlobalContext";
import MaterialTable, { Column } from "material-table";
import { GET_ALL_RATES, GET_ALL_RATES_CSV, RoutesList } from "router/url";
import tableIcons from "components/Table/icons";
import { getDateFormatted } from "utils/dateFormatter";
import { useDebounce } from "utils/hooks/useDebounce";
import { useFetch } from "utils/hooks/useFetch";
import { useHandleChangeEvent } from "utils/hooks/useHandleChangeEvent";
import { useToggle } from "utils/hooks/useToogle";
import { IDictionary } from "../../globalContext/types";
import { useStorage } from "../../utils/hooks/useStorage";
import monthToYearFormatter from "../../utils/monthToYearFormatter";
import RateForm from "./components/RateForm/RateForm";
import { Employee, EmployeesData } from "./EmployeeList.types";
import * as S from "./EmployeeList.css";
import { marginsAPISortParamsMap } from "./constants";
import { downloadFile } from "utils/downloadFile";

const sortState = { field: "", direction: "asc" };

type Checked = {
  client: boolean;
  employee: boolean;
};

interface IFilterEntry {
  value: string;
  label: string;
}

const EmployeeList = (): ReactElement => {
  const columns: Column<Employee>[] = [
    {
      field: "fullName",
      title: "Name",
    },
    {
      field: "positionCategory",
      title: "Position",
    },
    {
      field: "monthsOfItExperience",
      title: "IT experience",
      render: (item: Employee) => monthToYearFormatter(item?.monthsOfItExperience),
    },
    {
      field: "employeeDailyRate",
      title: "Daily rate",
      render: (item) => `${item.employeeDailyRate} ${item.employeeDailyRateCurrency}`,
    },
    {
      field: "employeeMonthlyGrossRate",
      title: "Monthly Gross Rate",
      render: (item) => `${item.employeeMonthlyGrossRate} ${item.employeeDailyRateCurrency}`,
    },
    {
      field: "employeeRateDateFrom",
      title: "Employee rate start",
    },
    {
      field: "clientPositionCategory.encryptedMaxRate",
      title: "Max client rate",
    },
    {
      field: "clientDailyRate",
      title: "Client rate",
      render: (item: Employee) => renderClientRate(item),
    },
    {
      field: "clientRateDateFrom",
      title: "Client rate start",
    },
    {
      field: "currency",
      title: "Client rate currency",
      hidden: true,
      export: true,
    },
    {
      field: "client",
      title: "Client",
    },
    {
      field: "team",
      title: "Team",
    },
    {
      field: "formOfEmployment",
      title: "Form of employment",
    },
    {
      field: "employedSince",
      title: "Employed since",
    },
    {
      field: "location",
      title: "Location",
    },
    {
      field: "endOfPartnershipDate",
      title: "End of partnership",
    },
    {
      field: "active",
      title: "Active",
    },
  ];

  let history = useHistory();

  const renderClientRate = (item: Employee) => {
    if (!item.clientDailyRate === null) {
      return "";
    }

    return `${item.clientDailyRate} ${item.currency !== null ? item.currency : ""}`;
  };

  const { clientOptions, locationOptions, positionCategoryOptions, fromQueryDate, toQueryDate } =
    useContext(GlobalContext)!;
  const mapDictionary = (arr: IDictionary[]): IFilterEntry[] =>
    arr.map((entry: IDictionary): IFilterEntry => ({ value: entry.uid, label: entry.name }));
  const mapPositionCategories = (arr: string[]): IFilterEntry[] =>
    arr.map((entry: string): IFilterEntry => ({ value: entry, label: entry }));
  const teams = mapDictionary(clientOptions);
  const locations = mapDictionary(locationOptions);
  const positionCategories = mapPositionCategories(positionCategoryOptions);

  const store = useStorage("filters");

  const [filters, setFilters] = useState<{
    team: IFilterEntry[];
    positionCategory: IFilterEntry[];
    project: IFilterEntry[];
    account: IFilterEntry[];
    location: IFilterEntry[];
  }>(
    store.get() || {
      team: [],
      positionCategory: [],
      project: [],
      account: [],
      location: [],
    }
  );

  const [checked, setChecked] = useState<Checked>({
    client: false,
    employee: false,
  });

  const onFilterChange = (filter: string) => (value: any) => {
    return setFilters((filters) => {
      const newFilters = {
        ...filters,
        ...(value ? { [filter]: [...value] } : { [filter]: [] }),
      };

      store.set(newFilters);
      return newFilters;
    });
  };

  const pageSizeOptions = [5, 10, 20, 50, 100, 200, 500, 3000];

  const { toggleLoading } = useContext(GlobalContext);
  const [employeesData, setEmployeesData] = useState<EmployeesData>();
  const [employeesTableData, setEmployeesTableData] = useState<Employee[]>([]);

  const getAllRates = useFetch();

  const selectQuery = (type: "team" | "positionCategory" | "project" | "account" | "location") =>
    filters[type].length > 0 ? `${type}=${filters[type].map((x) => x.label).join(",")}&` : "";
  const teamQuery = selectQuery("team");
  const positionQuery = selectQuery("positionCategory");
  const projectQuery = selectQuery("project");
  const accountQuery = selectQuery("account");
  const locationQuery = selectQuery("location");
  const [currentPage, setCurrentPage] = useState(0);

  const [minExperienceIt] = useHandleChangeEvent();
  const [maxExperienceIt] = useHandleChangeEvent();
  const [search, setSearch] = useHandleChangeEvent();
  const delayedMinExperienceIt = useDebounce(minExperienceIt, 800);
  const delayedMaxExperienceIt = useDebounce(maxExperienceIt, 800);
  const delayedSearch = useDebounce(search, 800);
  const maxExperienceQuery = delayedMaxExperienceIt ? `itExperienceMonthsMax=${delayedMaxExperienceIt}&` : "";
  const minExperienceQuery = delayedMinExperienceIt ? `itExperienceMonthsMin=${delayedMinExperienceIt}&` : "";
  const searchQuery = delayedSearch ? `searchText=${delayedSearch}&` : "";
  const pageNumberQuery = `page=${currentPage}`;

  const clientRateQuery = checked.client ? "&emptyClientRate=true" : "";
  const employeeRateQuery = checked.employee ? "&emptyEmployeeRate=true" : "";

  const getEmployeeList = useCallback(
    async (number: number = 3000) => {
      toggleLoading(true);
      const size = `size=${number}`;

      try {
        const response = await getAllRates(
          "GET",
          `${GET_ALL_RATES}?${locationQuery}${accountQuery}${teamQuery}${projectQuery}${positionQuery}${maxExperienceQuery}${minExperienceQuery}${searchQuery}${pageNumberQuery}&${size}${clientRateQuery}${employeeRateQuery}`
        );
        setEmployeesData(response);
        toggleLoading(false);
      } catch (error) {
        toast.error("Employee list couldn't be loaded. Try again!");
      }
    },
    [
      teamQuery,
      getAllRates,
      maxExperienceQuery,
      minExperienceQuery,
      pageNumberQuery,
      positionQuery,
      projectQuery,
      searchQuery,
      clientRateQuery,
      employeeRateQuery,
      accountQuery,
      locationQuery,
      toggleLoading,
    ]
  );

  useEffect(() => {
    getEmployeeList();
  }, [getEmployeeList, fromQueryDate, toQueryDate]);

  useEffect(() => {
    const employeesDataContent = employeesData?.content;
    if (employeesDataContent) {
      const employees = employeesDataContent.map((rate: any) => {
        return {
          ...rate,
          employeeUid: rate.uidNumber,
          itExperienceMonths: rate.monthsOfItExperience,
          employeeDailyRateStartingDate: getDateFormatted(rate.date),
        };
      });
      setEmployeesTableData(employees);
    }
  }, [employeesData]);

  const [openRateForm, , handleCloseRateForm] = useToggle();
  const [openDrawer, , handleCloseDrawer] = useToggle();

  const handleCheckboxChange = (type: keyof Checked) => (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    setChecked({ ...checked, [type]: value });
  };

  const numberOfEmployees = useMemo(() => {
    return new Set([...(employeesData?.content || []).map(({ employeeId }) => employeeId)]).size || 0;
  }, [employeesData]);

  return !employeesData ? (
    <></>
  ) : (
    <>
      <S.TopWrapper>
        <S.SearchPaper>
          <InputBase
            placeholder="Name and/or surname"
            name="search"
            fullWidth={false}
            value={search}
            onChange={setSearch}
          />
          <IconButton type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>
        </S.SearchPaper>
        <Box width="10%">
          <Select
            name="location"
            isMulti
            placeholder="Location..."
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={onFilterChange("location")}
            value={filters.location}
            options={locations}
          />
        </Box>
        <Box width="10%">
          {/* <S.FilterLabel>Team filter</S.FilterLabel> */}
          <Select
            name="team"
            isMulti
            placeholder="Team..."
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={onFilterChange("team")}
            value={filters.team}
            options={teams}
          />
        </Box>
        <Box width="10%">
          {/* <S.FilterLabel>Account filter</S.FilterLabel> */}
          <Select
            name="account"
            isMulti
            placeholder="Account..."
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={onFilterChange("account")}
            value={filters.account}
            options={teams}
          />
        </Box>
        <Box width="10%">
          {/* <S.FilterLabel>Account filter</S.FilterLabel> */}
          <Select
            name="positionCategory"
            isMulti
            placeholder="Position..."
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={onFilterChange("positionCategory")}
            value={filters.positionCategory}
            options={positionCategories}
          />
        </Box>
        <Box width="10%">
          <S.Checkbox
            control={
              <Checkbox
                checked={checked.client}
                onChange={handleCheckboxChange("client")}
                name="client"
                color="default"
              />
            }
            label="Empty client rate history"
          />
        </Box>
        <Box width="10%">
          <S.Checkbox
            control={
              <Checkbox
                checked={checked.employee}
                onChange={handleCheckboxChange("employee")}
                name="employee"
                color="primary"
              />
            }
            label="Empty employee rate history"
          />
        </Box>
      </S.TopWrapper>
      <Drawer anchor={"left"} open={openDrawer} onClose={handleCloseDrawer}>
        <S.WrapperContainer>
          {/* <S.FilterLabel>Location filter</S.FilterLabel>
          <Select
            name="location"
            isMulti
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            onChange={onFilterChange("location")}
            value={filters.location}
            options={locations}
          /> */}
          {/* <Box my="1.5rem">
            <S.FilterTextInput
              label="Min. IT experience (months)"
              type="number"
              name="itExperienceMonthsMax"
              variant="filled"
              value={minExperienceIt}
              onChange={setMinExperienceIt}
            />
          </Box>
          <Box>
            <S.FilterTextInput
              label="Max. IT experience (months)"
              type="number"
              variant="filled"
              name="itExperienceMonthsMax"
              value={maxExperienceIt}
              onChange={setMaxExperienceIt}
            />
          </Box> */}
        </S.WrapperContainer>
      </Drawer>
      <MaterialTable
        columns={columns}
        data={employeesTableData}
        page={currentPage}
        totalCount={employeesData.totalElements}
        onChangePage={setCurrentPage}
        onChangeRowsPerPage={getEmployeeList}
        icons={tableIcons}
        options={{
          emptyRowsWhenPaging: false,
          pageSize: employeesData.size,
          search: false,
          pageSizeOptions,
          exportButton: {
            pdf: false,
            csv: true,
          },
          exportCsv: async (columns, data) => {
            const response = await getAllRates(
              "GET",
              `${GET_ALL_RATES_CSV}?sort=${
                marginsAPISortParamsMap[sortState.field as keyof typeof marginsAPISortParamsMap] ||
                marginsAPISortParamsMap.fullName
              },${
                sortState.direction
              }&${locationQuery}${accountQuery}${teamQuery}${projectQuery}${positionQuery}${maxExperienceQuery}${minExperienceQuery}${searchQuery}${clientRateQuery}${employeeRateQuery}`,
              {},
              undefined,
              "blob"
            );
            downloadFile(response, "EmployeeMargins");
          },
          rowStyle: (rowData: any, index: number) => {
            return rowData.pendingEmployee
              ? {
                  backgroundColor: "rgb(255, 210, 66)",
                }
              : index % 2
              ? {
                  backgroundColor: "#EEE",
                }
              : {};
          },
        }}
        title={
          <>
            <S.TableTitle>All employee margins</S.TableTitle>
            {employeesData && employeesData.totalElements ? (
              <>
                <br />
                <S.TableSubtitle>Total number of margins: {employeesData.totalElements}</S.TableSubtitle>
                <S.TableSubsubtitle>Total number of employees: {numberOfEmployees}</S.TableSubsubtitle>
              </>
            ) : null}
          </>
        }
        onRowClick={(_, rowData) => {
          if (rowData) {
            history.push(`${RoutesList.EmployeeDetail}/${rowData.pendingLdapLogin}`);
          }
        }}
        onOrderChange={(orderBy, orderDirection) => {
          sortState.field = columns[orderBy]?.field || "";
          sortState.direction = orderDirection;
        }}
      />
      {openRateForm && (
        <RateForm onClose={handleCloseRateForm} getEmployeeList={getEmployeeList} editedItem={undefined} />
      )}
    </>
  );
};

export default EmployeeList;
