import AuthManager from "modules/AuthManager/AuthManager";
import Employees from "modules/Employees/Employees";
import EmployeeList from "modules/MarginList";
import EmployeeDetails from "modules/MarginList/screens/EmployeeDetails";
import RatesChanges from "modules/RatesChanges/RatesChanges";
import Reports from "modules/Reports";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutesList } from "router/url";

export default (
  <AuthManager>
    <Switch>
      <Route exact path={"/"}>
        <Employees />
      </Route>
      <Route exact path={RoutesList.Margins}>
        <EmployeeList />
      </Route>
      <Route exact path={`${RoutesList.Reports}`}>
        <Reports />
      </Route>
      <Route exact path={`${RoutesList.EmployeeDetail}/:employeePendingLdapLogin`}>
        <EmployeeDetails />
      </Route>
      <Route exact path={`${RoutesList.RatesChanges}`}>
        <RatesChanges />
      </Route>
      <Redirect to={"/"} />
    </Switch>
  </AuthManager>
);
