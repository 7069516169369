import React, { memo, ReactElement } from "react";
import { Control, Controller } from "react-hook-form";
import { PropTypes } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ErrorLabel } from "../index.css";

interface IProps<T> {
  control: Control;
  format: string;
  margin: PropTypes.Margin;
  name: string;
  label: string;
  disabled?: boolean;
  onChange: any;
  required?: boolean;
  rules?: Record<string, boolean>;
  errors: any;
  value: T;
  minDate?: Date;
}

const KeyboardDatepickerInput = (props: IProps<any>): ReactElement => {
  const { onChange, control, name, label, disabled, required, errors, format, margin, minDate, value } = props;
  return (
    <>
      <Controller
        as={
          <KeyboardDatePicker
            style={{ width: "100%" }}
            onChange={onChange}
            value={null}
            required={required}
            format={format}
            margin={margin}
            minDate={minDate}
          />
        }
        control={control}
        name={name}
        label={label}
        disabled={disabled}
        rules={{ required }}
        defaultValue={value}
      />
      <ErrorLabel>{errors[name] && "This field is required"}</ErrorLabel>
    </>
  );
};

export default memo(KeyboardDatepickerInput);
