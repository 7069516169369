import { FormControlLabel, Paper, SvgIconProps } from "@material-ui/core";
import { SyncAlt } from "@material-ui/icons";
import styled from "styled-components";
import TextInput from "components/TextInput/TextInput";

export const Checkbox = styled(FormControlLabel)`
  font-size: 1rem;
`;

export const TopWrapper = styled.div`
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
`;

export const FilterLabel = styled.p`
  color: white;
  margin-bottom: 0.5rem;
`;

export const FilterHeader = styled.h2`
  color: white;
`;

export const WrapperContainer = styled.div`
  min-width: 250px;
  padding: 2rem;
  background: -webkit-linear-gradient(left, orange, yellow, green, cyan, blue, violet); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, orange, yellow, green, cyan, blue, violet); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, orange, yellow, green, cyan, blue, violet); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to top, orange, yellow, green, cyan, blue, violet);
  height: 100%;
`;

export const FilterTextInput = styled(TextInput)`
  .MuiInputBase-root {
    background-color: white;
  }
`;

export const SearchPaper = styled(Paper)`
  padding-left: 1rem;
`;

export const TableTitle = styled.span`
  font-size: 22px;
  margin-top: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 0;
`;

export const TableSubtitle = styled.span`
  display: block;
  margin-top: -16px;
  font-size: 16px;
`;

export const TableSubsubtitle = styled(TableSubtitle)`
  margin-top: 0;
`;

export const SwitchIcon = styled(SyncAlt)<SvgIconProps>`
  &.MuiSvgIcon-root {
    transform: translateY(0.4rem);
    margin-top: -1.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    color: "white";
    &:hover {
      color: ${"#FFC003"};
    }
  }
`;
