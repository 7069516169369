import React from "react";
import { Box, Paper } from "@material-ui/core";
import StopIcon from "@material-ui/icons/Stop";
import EmployeeCurrentRates from "./components/EmployeeCurrentRates";

interface Props {
  getEmployeeDetails: () => void;
}
const EmployeeRatesOverview = ({ getEmployeeDetails }: Props) => {
  return (
    <Box>
      <Paper>
        <Box display="flex" alignItems="center" p="0.5rem" mb="1rem">
          <StopIcon style={{ color: "#EEE" }} /> Past
          <StopIcon style={{ color: "#000" }} /> Current
          <StopIcon style={{ color: "#BBB" }} /> Future
        </Box>
      </Paper>
      <EmployeeCurrentRates getEmployeeDetails={getEmployeeDetails} />
    </Box>
  );
};

export default EmployeeRatesOverview;
