import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiThemeProvider as ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import routes from "router/routes";
import { ToastProvider } from "components";
import "moment/locale/en-gb";
import { msalConfig } from "./authConfig";
import theme from "./themes";
import "./App.css";

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance
  .handleRedirectPromise()
  .then(() => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      msalInstance.loginRedirect();
    }
  })
  .catch((err) => {
    console.error(err);
  });

function App() {
  return (
    <Router>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>
              <StylesProvider injectFirst>
                <ToastProvider />
                {routes}
              </StylesProvider>
            </AuthenticatedTemplate>
          </MsalProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Router>
  );
}

export default App;
