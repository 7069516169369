const { AZURE_CLIENT_ID, AZURE_TENANT_ID, REDIRECT_URI } = window._env_;

export const msalConfig = {
  auth: {
    clientId: `${AZURE_CLIENT_ID || "e1ce52ab-0871-475a-90a4-16fe84df523a"}`,
    authority: `https://login.microsoftonline.com/${AZURE_TENANT_ID || "d1eaeb60-a904-4581-97bb-f9de26b4eaec"}`,
    redirectUri: `${REDIRECT_URI || "http://localhost:3000/myapp"}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [`api://${AZURE_CLIENT_ID || "e1ce52ab-0871-475a-90a4-16fe84df523a"}/app`],
};
