import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { Controller, EventFunction, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel, Grid, InputLabel, Typography } from "@material-ui/core";
import { GlobalContext } from "globalContext/GlobalContext";
import moment from "moment";
import { CALCULATE_RATES, RoutesList, SAVE_NEW_RATE, UPDATE_EMPLOYEE_RATE } from "router/url";
import { KeyboardDatepickerInput, Modal, NumberInput } from "components";
import { datePickerDateFormat } from "utils/constants";
import { getDateFormatted } from "utils/dateFormatter";
import { useDebounce } from "utils/hooks/useDebounce";
import { useFetch } from "utils/hooks/useFetch";
import { EmploymentForm, employmentsOptions } from "../../constants";
import { IRate, ISelectOption } from "./RateForm.types";
import * as S from "./RateForm.css";

interface Props {
  onClose: () => void;
  getEmployeeList: () => void;
  getEmployeeDetails?: () => void;
  editedItem?: any; //TODO type
}

const defaultValues: IRate = {
  employeeHourlyRate: 0,
  employeeDailyRate: 0,
  monthlyGrossRate: 0,
  monthlyNetRate: 0,
  formOfEmployment: employmentsOptions[0],
  currency: { value: "PLN", label: "PLN" },
  student: false,
  clientDailyRate: 0,
  startingDate: new Date(),
  endDate: new Date(),
};

const RateForm = ({ onClose, getEmployeeList, getEmployeeDetails, editedItem }: Props) => {
  let { employeePendingLdapLogin } = useParams();

  const { handleSubmit, control, getValues, reset, errors, setValue, watch } = useForm<IRate>(
    editedItem ? {} : { defaultValues }
  );

  const selectedCurrency = watch("currency");

  const globalContext = useContext(GlobalContext);
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);

  const { isLoading } = globalContext!;
  const currencyOptions = globalContext!.currencyOptions.map((el) => ({ label: el.name, value: el.name }));

  const [isStudentChecked, toggleStudentChecked] = useState(false);
  const [isPartTimeChecked, togglePartTimeChecked] = useState(false);

  const getAllCalculateRates = useFetch();

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const handleStartDateChange = (date: Date) => {
    setSelectedStartDate(date);
  };

  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const handleEndDateChange = (date: Date) => {
    setSelectedEndDate(date);
  };

  const calculateRates = useCallback(async () => {
    try {
      const values = getValues();
      const payload = {
        employeeDailyRate: Number(values.employeeDailyRate),
        monthlyGrossRate: Number(values.monthlyGrossRate),
        monthlyNetRate: Number(values.monthlyNetRate),
        formOfEmployment: (values.formOfEmployment as ISelectOption)?.value,
        student: values.student,
        date: getDateFormatted(selectedStartDate),
      };
      const response = await getAllCalculateRates("POST", CALCULATE_RATES, payload);
      reset({
        employeeHourlyRate: response.employeeDailyRate / 8 || null,
        employeeDailyRate: response.employeeDailyRate || null,
        monthlyGrossRate: response.monthlyGrossRate || null,
        monthlyNetRate: response.monthlyNetRate || null,
      });
    } catch (e) {
      toast.error("Rate calculation failed!");
    }
  }, [getAllCalculateRates, getValues, reset, selectedStartDate]);

  const [rateCalculationInputData, setRateCalculationInputData] = useState(defaultValues);

  const delayedRateCalculation = useDebounce(rateCalculationInputData, 500);

  useEffect(() => {
    if (delayedRateCalculation !== defaultValues) {
      calculateRates();
    }
  }, [calculateRates, delayedRateCalculation]);

  useEffect(() => {
    if (editedItem && !isInitiallyLoaded) {
      reset({
        ...editedItem,
        formOfEmployment: employmentsOptions.find((item) => item.value === editedItem.formOfEmployment),
        currency: currencyOptions.find((item) => item.value === editedItem.currency),
        employeeDailyRate: editedItem.dailyRate,
        employeeHourlyRate: editedItem.dailyRate / 8
      });
      setSelectedStartDate(moment(editedItem.dateFrom, "YYYY-MM-DD").toDate());

      setSelectedEndDate(editedItem.dateTo === "9999-12-31" ? null : moment(editedItem.dateTo, "YYYY-MM-DD").toDate());
      setIsInitiallyLoaded(true);
    }
  }, [isInitiallyLoaded, editedItem, reset]);

  const handleRateChange = ([e]: ChangeEvent<HTMLInputElement>[]) => {
    const value = e.target.value;
    const valueNumber = Number(value);
    const name = e.target.name;
    if (valueNumber && value !== "") {
      reset({ [name]: valueNumber });
      setRateCalculationInputData({
        ...rateCalculationInputData,
        [name]: valueNumber,
      });
    } else if (valueNumber) {
      reset({
        employeeHourlyRate: null,
        employeeDailyRate: null,
        monthlyGrossRate: null,
        monthlyNetRate: null,
      });
    }
    return e;
  };

  const postNewRate = useFetch();
  const patchNewRate = useFetch();

  const onSubmit = handleSubmit(async (data: IRate) => {
    const endDate = data.endDate ? { endDate: getDateFormatted(data.endDate) } : {};

    try {
      const payload = {
        clientUid: (data.clientId as any)?.uid,
        employeeDailyRate: data.employeeDailyRate || 0,
        pendingLdapLogin: employeePendingLdapLogin,
        formOfEmployment: (data.formOfEmployment as ISelectOption)?.value,
        monthlyGrossRate: data.monthlyGrossRate || 0,
        monthlyNetRate: data.monthlyNetRate || 0,
        clientDailyRate: data.clientDailyRate,
        currency: data.currency?.value,
        position: (data.positionId as any)?.uid,
        projectUid: (data.projectId as any)?.uid,
        startingDate: getDateFormatted(data.startingDate),
        ...endDate,
        student: isStudentChecked,
        partTimePercentage: data.partTimePercentage,
      };
      if (editedItem) {
        await patchNewRate("PATCH", UPDATE_EMPLOYEE_RATE(editedItem.id), payload);
      } else {
        await postNewRate("POST", SAVE_NEW_RATE, payload);
      }
      if (employeePendingLdapLogin && getEmployeeDetails) {
        getEmployeeDetails();
      }
      getEmployeeList();
      onClose();
      toast.success(
        <S.MessageLink to={`${RoutesList.EmployeeDetail}/${payload.pendingLdapLogin}`}>
          Rate has been saved! Click to open employee details.
        </S.MessageLink>
      );
    } catch (e) {
      toast.error("Rate has NOT been saved. Try again!");
    }
  });

  const onHourlyRateChange = ([e]: ChangeEvent<HTMLInputElement>[]) => {
      const employeeDailyRate = Number(e.target.value) * 8;
      setValue("employeeDailyRate", employeeDailyRate)
      return handleRateChange([e])
  }

  const onCurrencyChange: EventFunction = ([selected]) => {
    setValue("currency", selected);
    return selected;
  };

  const onStudentCheck: EventFunction = ([selected]) => {
    const newState = !isStudentChecked;
    toggleStudentChecked(newState);
    setRateCalculationInputData({
      ...rateCalculationInputData,
      student: newState,
    });
    return selected;
  };

  const onPartTimeChange: EventFunction = ([selected]) => {
    const newState = !isPartTimeChecked;
    togglePartTimeChecked(newState);
    return selected;
  };

  const onFormOfEmploymentChange: EventFunction = ([selected]) => {
    setRateCalculationInputData({
      ...rateCalculationInputData,
      employeeHourlyRate: 0,
      employeeDailyRate: 0,
      monthlyGrossRate: 0,
      monthlyNetRate: 0,
      formOfEmployment: selected,
    });
    reset({
      employeeHourlyRate: null,
      employeeDailyRate: null,
      monthlyGrossRate: null,
      monthlyNetRate: null,
      student: false,
    });
    return selected;
  };

  return (
    <Modal
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      title={`${editedItem ? "Update" : "Add"} employee rate`}
      width={employeePendingLdapLogin ? undefined : "lg"}
    >
      <Grid container spacing={3}>
        <Grid item xs={employeePendingLdapLogin ? 12 : 6}>
          <S.FormGrid>
            {!employeePendingLdapLogin && <Typography>Rates</Typography>}
            <InputLabel>Form of employment</InputLabel>
            <Controller
              as={<Select />}
              name="formOfEmployment"
              control={control}
              options={employmentsOptions}
              defaultValue={employmentsOptions[0]}
              onChange={onFormOfEmploymentChange}
            />
            <Controller
              as={
                <FormControlLabel
                  control={<Checkbox name="student" color="primary" />}
                  label="Is employee a student?"
                  disabled={
                    rateCalculationInputData.formOfEmployment.value === EmploymentForm.B2B ||
                    rateCalculationInputData.formOfEmployment.value === EmploymentForm.CONTRACT_OF_EMPLOYMENT
                  }
                />
              }
              onChange={onStudentCheck}
              name="student"
              control={control}
            />
            <Grid container xs={12} justify="space-between">
              {/* <Grid item xs={6}>
                <Controller
                  as={
                    <FormControlLabel
                      control={<Checkbox name="partTime" color="primary" />}
                      label="Part time?"
                      disabled={
                        rateCalculationInputData.formOfEmployment.value === EmploymentForm.B2B ||
                        rateCalculationInputData.formOfEmployment.value === EmploymentForm.CIVIL_CONTRACT
                      }
                    />
                  }
                  onChange={onPartTimeChange}
                  name="partTime"
                  control={control}
                />
              </Grid> */}
              {/* <Grid item xs={6}>
                <NumberInput
                  control={control}
                  name="partTimePercentage"
                  disabled={
                    rateCalculationInputData.formOfEmployment.value !== EmploymentForm.CONTRACT_OF_EMPLOYMENT ||
                    !isPartTimeChecked
                  }
                  label="Part time percentage [%]"
                  onChange={handleRateChange}
                  errors={errors}
                />
              </Grid> */}
              <Grid item xs={6}>
                <NumberInput
                  control={control}
                  name="employeeHourlyRate"
                  disabled={
                    rateCalculationInputData.formOfEmployment.value === EmploymentForm.CIVIL_CONTRACT ||
                    rateCalculationInputData.formOfEmployment.value === EmploymentForm.CONTRACT_OF_EMPLOYMENT
                }
                  label="Hourly rate"
                  onChange={onHourlyRateChange}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={<Select />}
                  name="currency"
                  control={control}
                  label="Currency"
                  options={currencyOptions}
                  rules={{ required: false }}
                  defaultValue={currencyOptions.find((el) => el.label === "PLN")}
                  styles={{
                            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                        }}
                  menuPortalTarget={document.body}
                  onChange={onCurrencyChange}
                  value={selectedCurrency}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  control={control}
                  name="employeeDailyRate"
                  disabled={rateCalculationInputData.formOfEmployment.value === EmploymentForm.CONTRACT_OF_EMPLOYMENT}
                  label="Daily rate"
                  onChange={handleRateChange}
                  errors={errors}
                />
              </Grid>

              <Grid item>
                <NumberInput
                  control={control}
                  name="monthlyGrossRate"
                  label="Monthly gross rate"
                  disabled={
                    rateCalculationInputData.formOfEmployment.value === EmploymentForm.B2B ||
                    rateCalculationInputData.formOfEmployment.value === EmploymentForm.CIVIL_CONTRACT
                  }
                  onChange={handleRateChange}
                  errors={errors}
                />
              </Grid>
              <Grid item>
                <NumberInput
                  control={control}
                  name="monthlyNetRate"
                  label="Monthly net rate"
                  disabled
                  errors={errors}
                />
              </Grid>
            </Grid>
            <KeyboardDatepickerInput
              format={datePickerDateFormat}
              margin="normal"
              control={control}
              name="startingDate"
              label="Starting date"
              onChange={handleStartDateChange}
              value={selectedStartDate}
              errors={errors}
              required
            />
            <KeyboardDatepickerInput
              format={datePickerDateFormat}
              margin="normal"
              control={control}
              name="endDate"
              label="End date"
              onChange={handleEndDateChange}
              value={selectedEndDate}
              errors={errors}
            />
            <Grid container xs={12} justify="space-between">
              <Grid item xs={6}>
                <Controller
                  as={
                    <FormControlLabel
                      control={<Checkbox name="partTime" color="primary" />}
                      label="Part time?"
                      disabled={
                        rateCalculationInputData.formOfEmployment.value === EmploymentForm.B2B ||
                        rateCalculationInputData.formOfEmployment.value === EmploymentForm.CIVIL_CONTRACT
                      }
                    />
                  }
                  onChange={onPartTimeChange}
                  name="partTime"
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  control={control}
                  name="partTimePercentage"
                  disabled={
                    rateCalculationInputData.formOfEmployment.value !== EmploymentForm.CONTRACT_OF_EMPLOYMENT ||
                    !isPartTimeChecked
                  }
                  label="Part time percentage [%]"
                  onChange={handleRateChange}
                  errors={errors}
                />
              </Grid>
            </Grid>
          </S.FormGrid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RateForm;
