import { LinearProgress } from "@material-ui/core";
import tableIcons from "components/Table/icons";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { GET_CUSTOM_REPORT, GET_CUSTOM_REPORT_CSV } from "router/url";
import { downloadFile } from "utils/downloadFile";
import { useFetch } from "utils/hooks/useFetch";

const getTableColumns = (data: string[][]) => data[0].map((d) => ({ field: d, title: d }));

const getTableData = (data: string[][]) =>
  data.slice(1).map((d) =>
    d.reduce((acc: any, val: string, index) => {
      acc[data[0][index]] = val;
      return acc;
    }, {})
  );

interface ISelectedCustomReportProps {
  customReportId: string;
}

const SelectedCustomReport = (props: ISelectedCustomReportProps) => {
  const { customReportId } = props;
  const fetch = useFetch();
  const [loading, setLoading] = useState(false);
  const [customReport, setCustomReport] = useState<string[][] | null>(null);

  const getCustomReport = useCallback(
    async (customReportId: string) => {
      setLoading(true);
      try {
        const response = await fetch("GET", GET_CUSTOM_REPORT(customReportId));
        setCustomReport(response);
        setLoading(false);
      } catch (error) {
        toast.error("Custom report couldn't be loaded. Try again!");
      }
    },
    [fetch, setCustomReport, setLoading]
  );

  useEffect(() => {
    if (customReportId) {
      getCustomReport(customReportId);
    }
  }, [customReportId]);

  const columns = useMemo(() => (customReport ? getTableColumns(customReport) : []), [customReport]);
  const tableData = useMemo(() => (customReport ? getTableData(customReport) : []), [customReport]);

  if (loading) return <LinearProgress color="primary" />;
  return (
    <MaterialTable
      columns={columns}
      data={tableData}
      icons={tableIcons}
      options={{
        search: false,
        paging: false,
        exportFileName: customReportId,
        exportButton: {
          pdf: false,
          csv: true,
        },
        exportCsv: async (columns, data) => {
          const response = await fetch("GET", GET_CUSTOM_REPORT_CSV(customReportId), {}, undefined, "blob");
          downloadFile(response, customReportId);
        },
      }}
      title={customReportId}
    />
  );
};

export default SelectedCustomReport;
